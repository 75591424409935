import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, Typography } from "@mui/material";
import React from "react";

interface NoticeProps {
	variant: "normal" | "error";
	message: string;
	buttonLabel?: string;
	callToAction?: () => void;
}
const Notice = ({
	variant,
	message,
	buttonLabel,
	callToAction,
}: NoticeProps) => {
	const variants = [
		{
			variant: "normal",
			backgroundColor: "#E6F3FA",
			iconColor: "#0288D1",
			textColor: "#013654",
			buttonColor: "#2F4D8B",
		},
		{
			variant: "error",
			backgroundColor: "#FBEAEA",
			iconColor: "#E44B48",
			textColor: "#541313",
			buttonColor: "#541313",
		},
	];

	const backgroundColor =
		variants.find((v) => v.variant === variant)?.backgroundColor ?? "#E6F3FA";
	const iconColor =
		variants.find((v) => v.variant === variant)?.iconColor ?? "#0288D1";
	const textColor =
		variants.find((v) => v.variant === variant)?.textColor ?? "#013654";
	const buttonColor =
		variants.find((v) => v.variant === variant)?.buttonColor ?? "#2F4D8B";

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				width: "100%",
				padding: "6px 16px",
				justifyContent: "space-between",
				alignItems: "center",
				borderRadius: "4px",
				backgroundColor: backgroundColor,
				maxWidth: "1155px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
					height: "35px",
					marginTop: "2px",
					marginBottom: "2px",
				}}
			>
				<InfoOutlinedIcon
					sx={{
						color: iconColor,
						width: "22px",
						height: "22px",
					}}
				/>
				<Typography
					flexWrap='wrap'
					sx={{
						color: textColor,
						fontFamily: "Roboto",
						fontSize: "14px",
						fontWeight: "400",
						lineHeight: "20.02px",
						letterSpacing: "0.17px",
						marginLeft: "12px",
					}}
				>
					{message}
				</Typography>
			</Box>
			{buttonLabel && (
				<Button
					variant='outlined'
					sx={{
						color: buttonColor,
						fontFamily: "Roboto",
						fontSize: "13px",
						fontWeight: "500",
						lineHeight: "22px",
						letterSpacing: "0.46px",
						textTransform: "uppercase",
						padding: "4px 10px",
						marginTop: "2px",
						marginBottom: "2px",
					}}
					onClick={
						callToAction
							? callToAction
							: () => {
									/*Do Nothing*/
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  }
					}
				>
					{buttonLabel}
				</Button>
			)}
			{!buttonLabel && <Box sx={{ width: "1px" }} />}
		</Box>
	);
};

export default Notice;
