// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
//import GigandTakeSwitch from "@gigandtake/switch";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {Autocomplete, CircularProgress, IconButton, TextField as Text,} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers-pro-6";
import {AdapterDayjs} from "@mui/x-date-pickers-pro-6/AdapterDayjs";
import format from "date-fns/format";
import dayjs from "dayjs";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-mui";
import React, {ReactNode, useEffect} from "react";
import {useDispatch} from "react-redux";

import {createDraftShiftAsync, fetchAllWorkcenters,} from "../../../../../../export/scheduler";
import {resetMaxWorkersByLocationJob} from "../../../../../components/jobs/store/jobs-slice";
import Toggle from "../../../../../design/toggle";
import JobColor from "../../../../../design/ui/job-color";
import useViewport from "../../../../../utils/use-viewport";
import useSchedulerData from "../../../controllers/use-scheduler-data";
import {fetchFilterAndShiftData} from "../../../store/scheduler-slice";
import {SchedulerWorkCenter} from "../../../store/types";
import ActionButtons from "./action-buttons";
// import AddNote from "./add-note";
import Approve from "./approve";
import useCnsDialog from "./controllers/use-create-new-shift-dialog";
import {useCnsDialogForm} from "./controllers/use-create-new-shift-dialog-form";
import SelectSkills from "./select-skills";
import {setToNearestTime, useStyles} from "./utils";

const scrollBarStyles = {
  height: "550px",
  width: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  "&::-webkit-scrollbar-corner": {
    background: "transparent",
  },
  "&::-webkit-resizer": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-button": {
    display: "none",
  },
};

const shiftDay: number = new Date().getDay();
const initDays: Record<number, boolean> = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  [shiftDay]: true,
};
const tempInitialShiftDateTimeValues = {
  startTime: "2022-10-19T10:00",
  endTime: "2022-10-19T18:00",
  workCenterId: 1,
  jobId: "",
  numberOfWorkersNeeded: "1",
  locationId: "",
  repeatShift: false,
  repeatOn: { ...initDays },
  shiftSkills: [],
  hexColor: "",
  shiftNote: "",
  shiftPurposeId: 0,
};

interface FilledFormControlProps {
  children: ReactNode;
  customStyles?: Record<string, string | number>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FilledFormControl: React.FC<FilledFormControlProps> = ({
  children,
  customStyles,
}) => {
  const classes = useStyles();
  return (
    <FormControl
      variant="filled"
      className={classes.formControlRoot}
      sx={{ ...customStyles }}
    >
      {children}
    </FormControl>
  );
};

type CNSDialogFormProps = {
  draftShiftDate: Date;
  handleClose: (() => void) | undefined;
  isShiftSwap?: boolean;
  requestComponent?: any;
  isShiftCoverage?: boolean | undefined;
};

const CNSDialogForm: React.FC<CNSDialogFormProps> = ({
  draftShiftDate,
  handleClose,
  isShiftSwap,
  requestComponent,
  isShiftCoverage,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { width } = useViewport();
  const [
    {
      schema,
      initialShiftDateTimeValues,
      filteredJobs,
      selectedDatesOfTheWeek,
      totalHours,
      totalMins,
    },
    {
      isDisabled,
      calculateTotalHoursFromEndTime,
      calculateTotalHoursFromStartTime,
      calculateTotalHoursFromWorkers,
      handleWorkcenterChange,
      getNumberOfStaff,
      setSelectedDatesOfTheWeek,
      setTotalHours,
    },
  ] = useCnsDialog({ draftShiftDate });

  const [
    ,
    {
      onFormSubmit,
      onEndTimeChange,
      onStartTimeChange,
      getLocationWorkcenters,
    },
  ] = useCnsDialogForm({
    setToNearestTime,
    dispatch,
    createDraftShiftAsync,
    setSelectedDatesOfTheWeek,
    calculateTotalHoursFromEndTime,
    calculateTotalHoursFromStartTime,
    isShiftSwap,
  });

  const [shiftType, setShiftType] = React.useState("jobs");

  const {
    maxWorkersByJobLocation,
    fetchFilterAndShiftDataStatus,
    workcenters,
    filterAllWorkcenters,
    isShiftBySkillEnabled,
    fetchAllWorkcentersStatus,
    shiftPurposes,
    isShiftPurposeEnabled,
  } = useSchedulerData();

  const locationWorkcenters =
    getLocationWorkcenters && getLocationWorkcenters(workcenters);

  // This is to ensure API is called only when filterAllWorkcenters is not available
  // Which happen on page refresh
  useEffect(() => {
    if (
      fetchFilterAndShiftDataStatus === "idle" &&
      fetchAllWorkcentersStatus === "idle"
    ) {
      dispatch(fetchAllWorkcenters());
      dispatch(fetchFilterAndShiftData());
    }
  }, [dispatch, fetchFilterAndShiftDataStatus, fetchAllWorkcentersStatus]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      // mr='auto'
      // ml='auto'
    >
      {isShiftSwap ? requestComponent : null}
      <Formik
        initialValues={
          initialShiftDateTimeValues
            ? initialShiftDateTimeValues
            : tempInitialShiftDateTimeValues
        }
        validationSchema={schema}
        onSubmit={(values, actions) => {
          if (onFormSubmit) onFormSubmit(values, actions);
        }}
      >
        {({ isValid, dirty, values, setFieldValue, isSubmitting, errors }) => (
          <Form className={classes.formRoot}>
            {/* <Box sx={scrollBarStyles}> */}
            {!isShiftPurposeEnabled ||
            (isShiftSwap && !isShiftCoverage) ? null : (
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={width < 600 ? "column" : "row"}
              >
                <Box width={"100%"}>
                  <FilledFormControl>
                    <Field
                      component={TextField}
                      type="text"
                      select
                      variant="filled"
                      name="shiftPurposeId"
                      id="shiftPurposeId"
                      label="Shift Purpose*"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue("shiftPurposeId", e.target.value);
                      }}
                      helperText=" "
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Ensure the label shrinks properly
                      }}
                      SelectProps={{
                        displayEmpty: true,
                        renderValue: (value: any) => {
                          if (!value || value === "") {
                            return (
                              <Typography
                                sx={{
                                  fontFamily: "Roboto",
                                  fontSize: "16px",
                                  color: "rgba(0, 0, 0, 0.6)",
                                }}
                              >
                                Select a purpose
                              </Typography>
                            );
                          }
                          const selectedPurpose = shiftPurposes?.[value];
                          return selectedPurpose ? selectedPurpose.name : "";
                        },
                      }}
                    >
                      {shiftPurposes &&
                        Object.values(shiftPurposes).map((purpose: any) => (
                          <MenuItem
                            value={purpose.id}
                            key={`shift-purpose-${purpose.id}`}
                          >
                            {purpose.name}
                          </MenuItem>
                        ))}
                    </Field>
                  </FilledFormControl>
                </Box>
              </Box>
            )}
            {isShiftSwap && !isShiftCoverage ? null : (
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={width < 600 ? "column" : "row"}
                mt={isShiftSwap ? 2 : 0}
                mb={2.5}
              >
                <Box width={width < 600 ? "100%" : "48%"} mr={1}>
                  {/* <FilledFormControl>
										<Field
											variant='filled'
											component={TextField}
											name='startTime'
											id='startTime'
											type='datetime-local'
											label='Shift Start Time'
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={
												{
													// min: new Date().toLocaleDateString(),
												}
											}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												if (onStartTimeChange && getNumberOfStaff) {
													onStartTimeChange(e, { setFieldValue, values });
													getNumberOfStaff(
														values.jobId,
														values.locationId,
														new Date(e.target.value).toISOString(),
														new Date(values.endTime).toISOString(),
														values.shiftSkills
													);
												}
											}}
										/>
									</FilledFormControl> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Shift Start Time"
                      value={
                        values.startTime
                          ? dayjs(values.startTime, "YYYY-MM-DDTHH:mm")
                          : null
                      }
                      format="MM/DD/YYYY, hh:mm A"
                      onChange={(newValue) => {
                        if (onStartTimeChange && getNumberOfStaff) {
                          const formattedDate =
                            dayjs(newValue).format("YYYY-MM-DDTHH:mm");
                          onStartTimeChange(formattedDate, {
                            setFieldValue,
                            values,
                          });
                          getNumberOfStaff(
                            values.jobId,
                            values.locationId,
                            new Date(formattedDate).toISOString(),
                            new Date(values.endTime).toISOString(),
                            values.shiftSkills
                          );
                        }
                      }}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          fullWidth: true,
                          InputLabelProps: {
                            shrink: true,
                          },
                          error: errors.startTime ? true : false,
                          helperText: errors.startTime ? errors.startTime : "",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box width={width < 600 ? "100%" : "48%"}>
                  {/* <FilledFormControl>
										<Field
											variant='filled'
											component={TextField}
											name='endTime'
											id='endTime'
											type='datetime-local'
											label='Shift End Time'
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={
												{
													// min: new Date().toLocaleDateString(),
												}
											}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												if (onEndTimeChange && getNumberOfStaff) {
													onEndTimeChange(e, { setFieldValue, values });
													getNumberOfStaff(
														values.jobId,
														values.locationId,
														new Date(values.startTime).toISOString(),
														new Date(e.target.value).toISOString(),
														values.shiftSkills
													);
												}
											}}
										/>
									</FilledFormControl> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Shift End Time"
                      value={
                        values.endTime
                          ? dayjs(values.endTime, "YYYY-MM-DDTHH:mm")
                          : null
                      }
                      format="MM/DD/YYYY, hh:mm A"
                      onChange={(newValue) => {
                        if (onEndTimeChange && getNumberOfStaff) {
                          const formattedDate =
                            dayjs(newValue).format("YYYY-MM-DDTHH:mm");
                          onEndTimeChange(formattedDate, {
                            setFieldValue,
                            values,
                          });
                          getNumberOfStaff(
                            values.jobId,
                            values.locationId,
                            new Date(values.startTime).toISOString(),
                            new Date(formattedDate).toISOString(),
                            values.shiftSkills
                          );
                        }
                      }}
                      slotProps={{
                        textField: {
                          variant: "filled",
                          fullWidth: true,
                          InputLabelProps: {
                            shrink: true,
                          },
                          error: errors.endTime ? true : false,
                          helperText: errors.endTime ? errors.endTime : "",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            )}

            {isShiftSwap && !isShiftCoverage ? null : (
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={width < 600 ? "column" : "row"}
              >
                <Box width={"100%"}>
                  <FilledFormControl>
                    <Field
                      component={TextField}
                      type="text"
                      select
                      variant="filled"
                      name="workCenterId"
                      id="workCenterId"
                      label="Location - Workcenter"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (handleWorkcenterChange) {
                          setFieldValue("workCenterId", e.target.value);
                          handleWorkcenterChange(e.target.value);
                          setFieldValue("jobId", "");
                        }
                        let locationWorkcenter = locationWorkcenters?.find(
                          (lwc: any) => lwc.id === e.target.value
                        );
                        if (fetchFilterAndShiftDataStatus === "fulfilled") {
                          locationWorkcenter = filterAllWorkcenters?.find(
                            (workcenter: SchedulerWorkCenter) =>
                              workcenter.id === parseInt(e.target.value)
                          );
                        }
                        if (locationWorkcenter) {
                          setFieldValue(
                            "locationId",
                            locationWorkcenter?.locationId
                          );
                        }
                      }}
                      helperText=" "
                      fullWidth
                    >
                      {fetchFilterAndShiftDataStatus === "fulfilled" &&
                      filterAllWorkcenters
                        ? filterAllWorkcenters.map(
                            (workcenter: SchedulerWorkCenter) => (
                              <MenuItem
                                value={`${workcenter.id}`}
                                key={`workcenter-${workcenter.id}`}
                                id={`${workcenter.id}`}
                              >
                                {workcenter.nameWithLocation}
                              </MenuItem>
                            )
                          )
                        : locationWorkcenters &&
                          locationWorkcenters.map((lwc: any) => (
                            <MenuItem value={lwc.id} key={lwc.id} id={lwc.id}>
                              {lwc.locationName} - {lwc.name}
                            </MenuItem>
                          ))}
                    </Field>
                  </FilledFormControl>
                </Box>
              </Box>
            )}

            {isShiftSwap && !isShiftCoverage ? null : (
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={width < 600 ? "column" : "row"}
                mb={0}
              >
                <Box width={width < 600 ? "100%" : "48%"} mr={1}>
                  <FilledFormControl>
                    <Field
                      component={TextField}
                      type="number"
                      variant="filled"
                      name="numberOfWorkersNeeded"
                      id="numberOfWorkersNeeded"
                      label="Number of workers required"
                      disabled={isSubmitting}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue("numberOfWorkersNeeded", e.target.value);
                        if (calculateTotalHoursFromWorkers)
                          calculateTotalHoursFromWorkers(
                            values,
                            e.target.value
                          );
                      }}
                    />
                  </FilledFormControl>
                </Box>
                <Box width={width < 600 ? "100%" : "48%"}>
                  <FilledFormControl>
                    <Field
                      component={TextField}
                      disabled
                      value={
                        totalHours !== undefined && totalMins !== undefined
                          ? `${
                              totalHours <= 0 ? "0 hrs" : `${totalHours} hrs`
                            } ${
                              totalMins <= 0 ? "0 mins" : `${totalMins} mins`
                            }`
                          : ""
                      }
                      type="text"
                      variant="filled"
                      label="Total Time"
                      name="total-hours"
                    />
                  </FilledFormControl>
                </Box>
              </Box>
            )}
            {(isShiftSwap && !isShiftCoverage) ||
            !isShiftBySkillEnabled ? null : (
              <Box mt={3} mb={1}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontFamily: "Roboto",
                  }}
                >
                  Shift type
                </Typography>
                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={shiftType}
                    onChange={(e) => {
                      if (e.target.value === "skills") {
                        setFieldValue("jobId", "");
                      } else {
                        setFieldValue("shiftSkills", []);
                      }
                      setShiftType(e.target.value);
                    }}
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="jobs"
                      control={<Radio />}
                      label="Job role"
                    />
                    <FormControlLabel
                      value="skills"
                      control={<Radio />}
                      label="Skills"
                    />
                  </RadioGroup>
                  {shiftType === "skills" ? (
                    <Tooltip
                      title="All default skill levels are set in the Admin section"
                      arrow
                      slotProps={{
                        popper: {
                          sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                              {
                                width: "150px",
                              },
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  ) : null}
                </FormControl>
              </Box>
            )}
            {shiftType === "skills" && isShiftBySkillEnabled ? (
              <SelectSkills
                values={values}
                setFieldValue={setFieldValue}
                getNumberOfStaff={getNumberOfStaff}
                resetMaxWorkersByLocationJob={() =>
                  dispatch(resetMaxWorkersByLocationJob())
                }
              />
            ) : isShiftSwap && !isShiftCoverage ? null : (
              <Box width={"100%"} mt={isShiftBySkillEnabled ? 0 : 2.5}>
                <FilledFormControl>
                  <Autocomplete
                    options={filteredJobs ?? []}
                    getOptionLabel={(option) => option.name}
                    disabled={filteredJobs?.length === 0}
                    value={
                      filteredJobs?.find(
                        (job: any) => job.id === values.jobId
                      ) ?? null
                    }
                    onChange={(e, newValue) => {
                      dispatch(resetMaxWorkersByLocationJob());
                      let locationWorkcenter = locationWorkcenters?.find(
                        (lwc: any) => lwc.id === values.workCenterId
                      );
                      if (fetchFilterAndShiftDataStatus === "fulfilled") {
                        locationWorkcenter = filterAllWorkcenters?.find(
                          (workcenter: SchedulerWorkCenter) =>
                            workcenter.id ===
                            parseInt(String(values.workCenterId))
                        );
                      }
                      if (locationWorkcenter && getNumberOfStaff) {
                        setFieldValue(
                          "locationId",
                          locationWorkcenter?.locationId
                        );
                        getNumberOfStaff(
                          newValue?.id ?? "",
                          locationWorkcenter.locationId,
                          new Date(values.startTime).toISOString(),
                          new Date(values.endTime).toISOString(),
                          []
                        );
                      }
                      setFieldValue("jobId", newValue?.id ?? "");
                      setFieldValue("hexColor", newValue?.hexColor ?? "");
                    }}
                    renderInput={(params) => {
                      return (
                        <Text
                          {...params}
                          variant="filled"
                          label="Job Role"
                          disabled={filteredJobs?.length === 0}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: values.jobId && (
                              <JobColor color={`#${values.hexColor ?? ""}`} />
                            ),
                          }}
                        />
                      );
                    }}
                    renderOption={(props, option) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "5px",
                        }}
                        component={"li"}
                        {...props}
                      >
                        <JobColor color={`#${option.hexColor}`} />
                        <Typography>{option.name}</Typography>
                      </Box>
                    )}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      marginTop: "2px",
                      color:
                        maxWorkersByJobLocation.data === 0 ? "red" : "auto",
                    }}
                  >
                    {maxWorkersByJobLocation.status === "pending" ? (
                      <CircularProgress size={20} />
                    ) : maxWorkersByJobLocation.status === "fulfilled" &&
                      values.jobId ? (
                      maxWorkersByJobLocation.data + " worker(s) have this job"
                    ) : (
                      ""
                    )}
                  </Typography>
                </FilledFormControl>
              </Box>
            )}
            {/* <AddNote /> */}
            {isShiftSwap && !isShiftCoverage ? null : (
              <Box width={"100%"} mr={1} mt={2} mb={2}>
                <FilledFormControl>
                  <Text
                    type="text"
                    variant="filled"
                    name="note"
                    id="note"
                    label="Note (optional)"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Worker will see this note when they apply for this shift"
                    multiline
                    rows={2}
                    disabled={isSubmitting}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue("shiftNote", e.target.value);
                    }}
                    error={values.shiftNote.length > 250}
                    helperText={
                      values.shiftNote.length > 250
                        ? "Note should be less than 250 characters"
                        : ""
                    }
                  />
                </FilledFormControl>
                <Box display="flex" justifyContent="flex-end">
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "19px",
                      fontFamily: "Roboto",
                      color: "#666666",
                    }}
                  >{`${values.shiftNote.length} / 250`}</Typography>
                </Box>
              </Box>
            )}
            {!isShiftSwap ? (
              <Box width="100%" mt={3} mb={3}>
                <Box width="30%" display="flex" alignItems="center">
                  <FilledFormControl customStyles={{ width: "30% !important" }}>
                    <Toggle
                      name="repeatShift"
                      id="repeatShift"
                      //type='checkbox'
                      onChange={() =>
                        setFieldValue("repeatShift", !values.repeatShift)
                      }
                    />
                  </FilledFormControl>

                  <Typography variant="caption" sx={{ fontSize: "14px" }}>
                    Repeat shift
                  </Typography>
                </Box>
              </Box>
            ) : null}

            {!isShiftSwap && values.repeatShift ? (
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                flexGrow={1}
                flexWrap="wrap"
                my={2}
                sx={{
                  opacity: `${values.repeatShift ? "" : "60%"}`,
                }}
              >
                {selectedDatesOfTheWeek &&
                  Object.values(selectedDatesOfTheWeek).map((day: any) => {
                    const currentDate: Date = new Date(day);
                    const currentDay: number = currentDate.getDay();
                    const shiftStartDate = new Date(values.startTime);

                    return (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        key={day}
                        borderRadius={2}
                        sx={{
                          opacity: `${
                            isDisabled &&
                            isDisabled(currentDate, shiftStartDate)
                              ? "60%"
                              : ""
                          }`,
                        }}
                      >
                        <Box mb={1}>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              opacity: `${
                                isDisabled &&
                                isDisabled(currentDate, shiftStartDate)
                                  ? "60%"
                                  : ""
                              }`,
                              color: `${
                                values.repeatOn[currentDay]
                                  ? "#2F4D8B"
                                  : "#666666"
                              }`,
                            }}
                          >
                            {format(currentDate, "EEE").toUpperCase()}
                          </Typography>
                        </Box>
                        <IconButton
                          onClick={() => {
                            if (values.repeatShift) {
                              setFieldValue(
                                `repeatOn[${currentDay}]`,
                                !values.repeatOn[currentDay]
                              );
                            }
                          }}
                          disabled={
                            isDisabled &&
                            isDisabled(currentDate, shiftStartDate)
                          }
                        >
                          <Avatar
                            style={{
                              backgroundColor: `${
                                values.repeatOn[currentDay]
                                  ? "#2F4D8B"
                                  : "rgba(0, 0, 0, 0.06)"
                              }`,
                              color: `${
                                values.repeatOn[currentDay] ? "#fff" : "#666666"
                              }`,
                            }}
                          >
                            <Typography sx={{ fontSize: "1rem" }}>
                              {new Date(day).getDate()}
                            </Typography>
                          </Avatar>
                        </IconButton>
                      </Box>
                    );
                  })}
              </Box>
            ) : null}
            {/* </Box> */}
            {/* Create Button */}
            {isShiftSwap && !isShiftCoverage ? (
              <Approve handleClose={handleClose} />
            ) : (
              <ActionButtons
                handleClose={() => {
                  handleClose && handleClose();
                  dispatch(resetMaxWorkersByLocationJob());
                }}
                isValid={isValid}
                dirty={dirty}
                isSubmitting={isSubmitting}
                isShiftSwap={isShiftSwap}
                isDisabled={
                  shiftType === "skills"
                    ? values.shiftSkills.length === 0
                    : values.jobId === "" ||
                      values.jobId === undefined ||
                      (isShiftPurposeEnabled &&
                        (values.shiftPurposeId === 0 ||
                          values.shiftPurposeId === ""))
                }
                jobSelected={!!values.jobId}
                skillSelected={!!values.shiftSkills.length}
                availableWorkers={maxWorkersByJobLocation.data ?? 0}
              />
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CNSDialogForm;
