import { Box } from "@mui/material";
import React, { useState } from "react";

import {
	ColumnSelector,
	WorkerActivityData,
} from "../../store/worker-activity-slice-types";
import MonthlyActivityTable from "./monthly-activity-table";
import TableHeader from "./table-header";

interface ActivityTableProps {
	date?: [string, string];
	setDate?: (date: [string, string]) => void;
	data?: WorkerActivityData[];
	search?: string;
	setSearch?: (search: string) => void;
	columnSelector?: ColumnSelector[];
	setColumnSelector?: (columnSelector: ColumnSelector[]) => void;
	exportData?: () => void;
}

const ActivityTable = ({
	date,
	setDate,
	data,
	exportData,
	search,
	setSearch,
	columnSelector,
	setColumnSelector,
}: ActivityTableProps) => {
	return (
		<Box sx={{ mt: 2 }}>
			<TableHeader
				date={date}
				setDate={setDate}
				search={search}
				setSearch={setSearch}
				columnSelector={columnSelector}
				setColumnSelector={setColumnSelector}
				exportData={exportData}
			/>
			<MonthlyActivityTable
				data={data}
				search={search}
				columnSelector={columnSelector}
			/>
		</Box>
	);
};

export default ActivityTable;
