// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import ArrowBack from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import {FadeProps, Skeleton, TextField} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import {GridRowId} from "@mui/x-data-grid";
import isPast from "date-fns/isPast";
import _ from "lodash";
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {assignWorkers, renotifyWorkers, resetShiftDetails,} from "../../../../../../export/scheduler";
import {
  ASSIGN_SHIFT,
  LOCATION,
  PRIMARYJOB,
  SET_PREFERRED_WORKERS,
  SHIFT_DETAILS,
  WORKCENTER,
} from "../../../../../assets/constants";
import CheckboxPopover from "../../../../../design/checkbox-popover";
import {formatDate, getHourMinuteDifference,} from "../../../../../utils/helpers";
import {
  resetAssignWorkers,
  resetAssignWorkersResponse,
  resetSetOTWorkers,
  resetSetOTWorkersResponse,
  setOTWorkers,
} from "../../../store/scheduler-slice";
import AssignShiftTable from "../../shift-details/assign-shift-table";
import Notice from "../../shift-details/notice";
import ShiftDetailsTable from "../../shift-details/ShiftDetailsTable";
import ShiftMetaData from "../../shift-details/ShiftMetaData";
import {WorkersExtendedPropV2} from "../shift-details-dialog/types";
import {ASSIGNED} from "./constants";
import useShiftDetailsDialogController from "./controllers/use-shift-details-dialog-controller";
import ReviewWorkerAssignShiftCheckbox from "./review-worker-assign-shift-checkbox";

interface ShiftDetailsDialogProps {
  open: boolean;
  handleClose: any;
  shiftId: any;
  isWorkerRequest: boolean;
}

interface AssignWorkerResponseProp {
  workerId: string;
  shiftId: number;
  isSuccess: boolean;
  message: string;
}

const ShiftDetailsDialog: React.FC<ShiftDetailsDialogProps> = ({
  open,
  handleClose,
  shiftId,
  isWorkerRequest,
}) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(SHIFT_DETAILS);
  const [search, setSearch] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [columnAnchorEl, setColumnAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const userRoles = useSelector((state: any) => state.user.userData.userRoles);
  const [
    {
      shiftMetadata,
      jobRoleMetadata,
      workCenterMetadata,
      availableWorkersForShiftRow,
      columns,
      allSkillLevels,
      confirmedWorkersCount,
      appliedWorkersCount,
      iAmSure,
      rowsV2,
      statusOptions,
      availablePreferredWorkersForShiftRow,
      status,
    },
    {
      setAvailableWorkersForShiftRow,
      setAvailablePreferredWorkersForShiftRow,
      setRowsV2,
      setIAmSure,
      setStatusOptions,
      setColumns,
    },
  ] = useShiftDetailsDialogController(
    shiftId,
    isWorkerRequest,
    search,
    activeStep
  );

  const [workersSelected, setWorkersSelected] = useState<GridRowId[]>([]);
  const [workersSelectedForAssignShift, setWorkersSelectedForAssignShift] =
    useState<GridRowId[]>([]);
  const [workersPreferredForAssignShift, setWorkersPreferredForAssignShift] =
    useState<GridRowId[]>([]);
  const [
    originalWorkersPreferredForAssignShift,
    setOriginalWorkersPreferredForAssignShift,
  ] = useState<GridRowId[]>([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<
    number[]
  >([]);

  const errorMessage = useSelector(
    (state: any) => state.supervisor?.shiftDetailsV2?.errorMessage
  );

  const renotifyWorkersStatus = useSelector(
    (state: any) => state.supervisor?.renotifyWorkers.status
  );

  const assignWorkersStatus = useSelector(
    (state: any) => state.supervisor?.assignWorkers.status
  );

  const assignWorkersResponse: AssignWorkerResponseProp[] = useSelector(
    (state: any) => state.supervisor?.assignWorkers.response
  );

  const enableManualAssignment = useSelector(
    (state: any) =>
      state.supervisor?.shiftDetailsV2?.data?.enableManualAssignment
  );

  const getOTWorkersResponse = useSelector(
    (state: any) => state.supervisor?.getOTWorkers.response
  );

  const getOTWorkersStatus = useSelector(
    (state: any) => state.supervisor?.getOTWorkers.status
  );

  const setOTWorkersStatus = useSelector(
    (state: any) => state.supervisor?.setOTWorkers.status
  );

  const setOTWorkersResponse = useSelector(
    (state: any) => state.supervisor?.setOTWorkers.response
  );

  const setOTWorkersWorkersPreferredForAssignShift = useSelector(
    (state: any) => state.supervisor?.setOTWorkers.workersPreferredForAssignShift
  );

  const handleSnackbarEnter = () => {
    setOriginalWorkersPreferredForAssignShift(setOTWorkersWorkersPreferredForAssignShift)
  };

  const Transition = (props: FadeProps) => {
    return <Fade {...props} onEnter={handleSnackbarEnter} />;
  };

  const canShowNoticeForForceAssign = () => {
    try {
      const isReadOnly = Array.isArray(userRoles)
        ? userRoles.includes("ReadOnly")
        : false;
      const requirementFulfilled =
        confirmedWorkersCount !== undefined
          ? parseInt(shiftMetadata.numberOfWorkersNeeded) -
              confirmedWorkersCount ===
            0
          : parseInt(shiftMetadata?.numberOfWorkersNeeded) -
              parseInt(shiftMetadata?.slotsFilled) ===
            0;
      return !requirementFulfilled && !isReadOnly && enableManualAssignment;
    } catch (e) {
      return false;
    }
  };

  const canShowWorkerPreferences = () => {
    try {
      const isReadOnly = Array.isArray(userRoles)
        ? userRoles.includes("ReadOnly")
        : false;
      const requirementFulfilled =
        confirmedWorkersCount !== undefined
          ? parseInt(shiftMetadata.numberOfWorkersNeeded) -
              confirmedWorkersCount ===
            0
          : parseInt(shiftMetadata?.numberOfWorkersNeeded) -
              parseInt(shiftMetadata?.slotsFilled) ===
            0;
      const beforeShiftStartWindow =
        getHourMinuteDifference(new Date(shiftMetadata?.startDate), new Date())
          .differenceInMinutes > 0;
      const allowedShiftAllocationType =
        shiftMetadata?.shiftAllocationType === WORKCENTER ||
        shiftMetadata?.shiftAllocationType === LOCATION ||
        shiftMetadata?.shiftAllocationType === PRIMARYJOB;
      const autoForceAssignmentEnabled =
        shiftMetadata?.autoForceAssignment === true;
      const closedForInterest = shiftMetadata?.windowClosingTime
        ? new Date().getTime() >=
          new Date(shiftMetadata.windowClosingTime).getTime()
        : false;
      return (
        !requirementFulfilled &&
        !isReadOnly &&
        beforeShiftStartWindow &&
        !closedForInterest &&
        autoForceAssignmentEnabled &&
        allowedShiftAllocationType &&
        getOTWorkersStatus === "fulfilled"
      );
    } catch (e) {
      return false;
    }
  };

  const getAssignedWorkerSuccessMessage = () => {
    let returnValue = "";
    if (Array.isArray(assignWorkersResponse)) {
      const successWorkers = assignWorkersResponse.filter(
        (assignWorker) => assignWorker.isSuccess
      );
      if (successWorkers.length > 0) {
        returnValue = `Shift assigned to ${successWorkers.length} worker${
          successWorkers.length > 1 ? "s" : ""
        }`;
      } else {
        returnValue = "";
      }
    }
    return returnValue;
  };

  const getPreferredWorkerAssignedSuccessMessage = () => {
    let returnValue = "";
    if (Array.isArray(setOTWorkersResponse)) {
      const successfullyAddedWorkers = setOTWorkersResponse.filter(
        (assignWorker) => assignWorker.decision === 1
      );
      const successfullyRemovedWorkers = setOTWorkersResponse.filter(
        (assignWorker) => assignWorker.decision === 2
      );
      const noChangeWorkers = setOTWorkersResponse.filter(
        (assignWorker) => assignWorker.decision === 0
      );
      const stats = [];
      if (successfullyAddedWorkers.length > 0) {
        stats.push(`Added: ${successfullyAddedWorkers.length}`);
      }
      if (successfullyRemovedWorkers.length > 0) {
        stats.push(`Removed: ${successfullyRemovedWorkers.length}`);
      }
      if (noChangeWorkers.length > 0) {
        stats.push(`Unchanged: ${noChangeWorkers.length}`);
      }
      returnValue = `Updated Workers for Forced Overtime ${
        stats.length > 0 ? `(${stats.join(", ")})` : ""
      }`;
    }
    return returnValue;
  };

  useEffect(() => {
    try {
      if (getOTWorkersStatus === "fulfilled") {
        if (
          Array.isArray(getOTWorkersResponse) &&
          getOTWorkersResponse?.length > 0 &&
          setAvailablePreferredWorkersForShiftRow
        ) {
          setAvailablePreferredWorkersForShiftRow((prevState) => {
            let newState: WorkersExtendedPropV2[] = [];
            const previousState = prevState ? [...prevState] : [];
            getOTWorkersResponse.forEach((preferredWorker) => {
              const selectedWorker = previousState.find(
                (worker) => worker.workerId === preferredWorker.workerId
              );
              if (!selectedWorker) {
                newState.push({
                  ...preferredWorker,
                  id: preferredWorker.workerId,
                  reason: "Worker is no longer eligible for Forced Overtime",
                });
              }
            });
            previousState.forEach((worker) => {
              newState.push(worker);
            });
            newState = newState.filter((obj: any, index: number) => {
              return index === newState.findIndex((o: any) => obj.id === o.id);
            });
            newState = _.orderBy(newState, "order", ["asc"]);
            return newState;
          });
          setWorkersPreferredForAssignShift(
            [...getOTWorkersResponse]
              .sort((a, b) => a.workerId.localeCompare(b.workerId))
              .map((preferredWorker) => preferredWorker.workerId)
          );
          setOriginalWorkersPreferredForAssignShift(
            [...getOTWorkersResponse]
              .sort((a, b) => a.workerId.localeCompare(b.workerId))
              .map((preferredWorker) => preferredWorker.workerId)
          );
        }
      }
    } catch (e) {
      // Do nothing
    }
  }, [
    getOTWorkersResponse,
    getOTWorkersStatus,
    setAvailablePreferredWorkersForShiftRow,
  ]);

  useEffect(() => {
    if (setOTWorkersStatus === "error") {
      setWorkersPreferredForAssignShift(originalWorkersPreferredForAssignShift);
    }
  }, [setOTWorkersStatus, originalWorkersPreferredForAssignShift]);

  useEffect(() => {
    try {
      if (setOTWorkersStatus === "fulfilled") {
        if (
          Array.isArray(setOTWorkersResponse) &&
          setOTWorkersResponse.length > 0 &&
          setAvailablePreferredWorkersForShiftRow
        ) {
          setAvailablePreferredWorkersForShiftRow((prevState) => {
            let newState: WorkersExtendedPropV2[] = [];
            const previousState = prevState ? [...prevState] : [];
            previousState.forEach((worker) => {
              let newWorkerObject = { ...worker };
              setOTWorkersResponse.forEach((workerAndDecision) => {
                if (
                  workerAndDecision.workerId === worker.workerId &&
                  workerAndDecision.decision !== 1 &&
                  workerAndDecision.decision !== 0 &&
                  workerAndDecision.decision !== 2
                ) {
                  newWorkerObject = {
                    ...worker,
                    errorMessage:
                      "Unable to set above worker for Forced Overtime",
                  };
                }
              });
              newState.push(newWorkerObject);
            });
            newState = newState.filter((obj: any, index: number) => {
              return index === newState.findIndex((o: any) => obj.id === o.id);
            });
            newState = _.orderBy(newState, "order", ["asc"]);
            return newState;
          });
          const failedWorkers = setOTWorkersResponse.filter(
            (preferredWorker) =>
              preferredWorker.decision !== 1 &&
              preferredWorker.decision !== 0 &&
              preferredWorker.decision !== 2
          );
          if (failedWorkers && failedWorkers.length > 0) {
            setDetailPanelExpandedRowIds(
              failedWorkers.map((preferredWorker) => preferredWorker.workerId)
            );
          } else {
            setDetailPanelExpandedRowIds([]);
          }
        }
      }
    } catch (e) {
      // Do nothing
    }
  }, [setOTWorkersResponse, setOTWorkersStatus, setAvailablePreferredWorkersForShiftRow]);

  useEffect(() => {
    try {
      if (assignWorkersStatus === "fulfilled") {
        if (
          Array.isArray(assignWorkersResponse) &&
          setAvailableWorkersForShiftRow &&
          setRowsV2 &&
          setIAmSure
        ) {
          // All successfully assigned workers
          const successWorkers = assignWorkersResponse.filter(
            (assignedWorker) => assignedWorker.isSuccess
          );
          const successWorkersIds = successWorkers.map((successWorker) => {
            return successWorker.workerId;
          });

          // All workers having error while assigning
          const errorWorkers = assignWorkersResponse.filter(
            (assignedWorker) => !assignedWorker.isSuccess
          );
          const errorWorkersIds = errorWorkers.map((errorWorker) => {
            return errorWorker.workerId;
          });

          // Remove confirmed workers and show error to those who can not be assigned
          setAvailableWorkersForShiftRow((prevState) => {
            let newState: WorkersExtendedPropV2[] = [];
            const previousState = prevState ? [...prevState] : [];
            previousState.forEach((worker) => {
              if (errorWorkersIds.includes(worker.workerId)) {
                const selectedResponse = errorWorkers.find(
                  (errorWorker) => errorWorker.workerId === worker.workerId
                );
                if (selectedResponse) {
                  newState.push({
                    ...worker,
                    errorMessage: selectedResponse.message,
                  });
                }
              } else {
                if (!successWorkersIds.includes(worker.workerId)) {
                  newState.push(worker);
                }
              }
            });
            newState = _.orderBy(newState, "errorMessage", ["desc"]);
            newState = newState.filter((obj: any, index: number) => {
              return index === newState.findIndex((o: any) => obj.id === o.id);
            });
            newState = _.orderBy(newState, "order", ["asc"]);
            if (newState.length === 0) {
              return undefined;
            }
            return newState;
          });

          // Update main workers list with confirmed status
          setRowsV2((prevState) => {
            let newState: WorkersExtendedPropV2[] = [];
            prevState.forEach((worker) => {
              if (successWorkersIds.includes(worker.workerId)) {
                newState.push({
                  ...worker,
                  status: ASSIGNED,
                  reason: "",
                  order: 2,
                });
              } else if (errorWorkersIds.includes(worker.workerId)) {
                const selectedResponse = errorWorkers.find(
                  (errorWorker) => errorWorker.workerId === worker.workerId
                );
                if (selectedResponse) {
                  newState.push({
                    ...worker,
                    errorMessage: selectedResponse.message,
                  });
                }
              } else {
                newState.push(worker);
              }
            });
            newState = newState.filter((obj: any, index: number) => {
              return index === newState.findIndex((o: any) => obj.id === o.id);
            });
            newState = _.orderBy(newState, "order", ["asc"]);
            return newState;
          });

          // If non fail then navigate to previous step
          const isFailed = assignWorkersResponse.find(
            (response) => !response.isSuccess
          );
          if (!isFailed) {
            setActiveStep(SHIFT_DETAILS);
          }
          setIAmSure(false);
        }
      }
    } catch (e) {
      // Do nothing
    }
  }, [
    assignWorkersResponse,
    assignWorkersStatus,
    setAvailableWorkersForShiftRow,
    setRowsV2,
    setIAmSure,
  ]);

  // const publishedDateISOString =
  //   shiftMetadata?.publishedOn &&
  //   new Date(
  //     new Date(shiftMetadata?.publishedOn)?.getTime() -
  //       new Date()?.getTimezoneOffset() * 60 * 1000
  //   ).toISOString();

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="add-new-shift"
        open={open}
        maxWidth={false}
      >
        <DialogTitle
          id="customized-dialog-title"
          maxWidth={1200}
          sx={{
            padding: 0,
          }}
        >
          {activeStep === SHIFT_DETAILS && (
            <>
              <ShiftMetaData
                shiftMetadata={shiftMetadata}
                jobRoleMetadata={jobRoleMetadata}
                workCenterMetadata={workCenterMetadata}
                allSkillLevels={allSkillLevels}
                confirmedWorkersCount={confirmedWorkersCount}
                showSkeleton={status === "pending"}
              />
              {canShowWorkerPreferences() && (
                <Box
                  sx={{
                    padding: "0px 45px 5px 45px",
                  }}
                >
                  <Notice
                    variant="normal"
                    message={`${
                      originalWorkersPreferredForAssignShift
                        ? originalWorkersPreferredForAssignShift.length === 0
                          ? "0 Workers"
                          : originalWorkersPreferredForAssignShift.length > 1
                          ? originalWorkersPreferredForAssignShift.length +
                            " Workers"
                          : originalWorkersPreferredForAssignShift.length +
                            " Worker"
                        : "0 Workers"
                    } selected for Forced Overtime`}
                    buttonLabel={SET_PREFERRED_WORKERS}
                    callToAction={() => {
                      setActiveStep(SET_PREFERRED_WORKERS);
                    }}
                  />
                </Box>
              )}
              {canShowNoticeForForceAssign() && (
                <Box
                  sx={{
                    padding: "0px 45px 5px 45px",
                  }}
                >
                  {/* In case there is no worker to assign when trying to assign worker */}
                  {availableWorkersForShiftRow !== undefined &&
                    availableWorkersForShiftRow.length === 0 && (
                      <Notice
                        message="No workers available for shift assignment."
                        variant="error"
                      />
                    )}
                  {/* When there are some worker which can be assigned */}
                  {availableWorkersForShiftRow !== undefined &&
                    availableWorkersForShiftRow.length > 0 && (
                      <Notice
                        variant="normal"
                        message="Not enough employees agreed to come for the OT shift posted. Please assign the employees needed and contact the employee directly to inform them that they are being assigned to the forced OT shift."
                        buttonLabel={ASSIGN_SHIFT}
                        callToAction={() => {
                          setActiveStep(ASSIGN_SHIFT);
                        }}
                      />
                    )}
                </Box>
              )}
            </>
          )}
          {/* This header is for setting preferred worker*/}
          {activeStep === SET_PREFERRED_WORKERS && (
            <Box
              sx={{
                padding: "45px 45px 5px 45px",
                width: "100%",
                maxWidth: "1200px",
              }}
            >
              <Box
                mb={1.5}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setWorkersPreferredForAssignShift(originalWorkersPreferredForAssignShift);
                    setActiveStep(SHIFT_DETAILS);
                  }}
                >
                  <ArrowBack
                    style={{
                      color: "rgba(0, 0, 0, 0.87)",
                      width: "32px",
                      height: "32px",
                      padding: "4px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flexStart",
                    marginLeft: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "24px",
                      fontWeight: "400",
                      lineHeight: "31px",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  >
                    Force Overtime Preference
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "28px",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  >
                    {appliedWorkersCount !== undefined &&
                    appliedWorkersCount > 0
                      ? `${appliedWorkersCount} Worker${
                          appliedWorkersCount > 1 ? "s" : ""
                        } Applied, ${parseInt(
                          shiftMetadata.numberOfWorkersNeeded
                        )} Worker${
                          parseInt(shiftMetadata.numberOfWorkersNeeded) > 1
                            ? "s"
                            : ""
                        } required`
                      : `0 Workers Applied, ${parseInt(
                          shiftMetadata?.numberOfWorkersNeeded
                        )} Worker${
                          parseInt(shiftMetadata?.numberOfWorkersNeeded) > 1
                            ? "s"
                            : ""
                        } required`}
                  </Typography>
                </Box>
              </Box>
              <Box
                mb={1.5}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flexStart",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "28px",
                      color: "rgba(0, 0, 0, 0.87)",
                      marginTop: "12px",
                    }}
                  >
                    {`Workers for Forced Overtime (${
                      workersPreferredForAssignShift
                        ? workersPreferredForAssignShift.length
                        : "0"
                    })`}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  >
                    Only selected Workers will be considered for Forced Overtime
                    after Signup window closes
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {/* This header is for assign shift to worker */}
          {activeStep === ASSIGN_SHIFT && (
            <Box
              sx={{
                padding: "45px 45px 5px 45px",
                width: "100%",
                maxWidth: "1200px",
              }}
            >
              <Box
                mb={1.5}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => setActiveStep(SHIFT_DETAILS)}
                >
                  <ArrowBack
                    style={{
                      color: "rgba(0, 0, 0, 0.87)",
                      width: "32px",
                      height: "32px",
                      padding: "4px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flexStart",
                    marginLeft: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "24px",
                      fontWeight: "400",
                      lineHeight: "31px",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  >
                    Assign shift
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "28px",
                      color: "rgba(0, 0, 0, 0.87)",
                      marginTop: "12px",
                    }}
                  >
                    {confirmedWorkersCount !== undefined
                      ? `${confirmedWorkersCount} / ${
                          shiftMetadata.numberOfWorkersNeeded
                        } workers confirmed, ${
                          parseInt(shiftMetadata.numberOfWorkersNeeded) -
                          confirmedWorkersCount
                        } more worker${
                          parseInt(shiftMetadata.numberOfWorkersNeeded) -
                            confirmedWorkersCount >
                          1
                            ? "s"
                            : ""
                        } required`
                      : `${shiftMetadata?.slotsFilled} / ${
                          shiftMetadata?.numberOfWorkersNeeded
                        } workers confirmed, ${
                          parseInt(shiftMetadata?.numberOfWorkersNeeded) -
                          parseInt(shiftMetadata?.slotsFilled)
                        } more worker${
                          parseInt(shiftMetadata?.numberOfWorkersNeeded) -
                            parseInt(shiftMetadata?.slotsFilled) >
                          1
                            ? "s"
                            : ""
                        } required`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </DialogTitle>
        <DialogContent sx={{ maxWidth: "1200px", minHeight: 250 }}>
          <>
            {/* Render search only when we have some rows in table */}
            {((activeStep === ASSIGN_SHIFT &&
              availableWorkersForShiftRow !== undefined &&
              availableWorkersForShiftRow.length > 0) ||
              ((activeStep === SHIFT_DETAILS ||
                activeStep === SET_PREFERRED_WORKERS) &&
                rowsV2 !== undefined &&
                rowsV2.length > 0)) && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "57px",
                  width: "100%",
                  paddingLeft: "21px",
                  paddingRight: "21px",
                  gap: 2,
                }}
              >
                {/* Currently Rendering filter only when we are in shift details step */}
                {activeStep === SHIFT_DETAILS && (
                  <>
                    <Box
                      sx={{
                        flex: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 0.5,
                        borderRadius: "4px",
                        background: "#0000000A",
                        ":hover": {
                          cursor: "pointer",
                          background: "#0000001F",
                        },
                      }}
                      onClick={(e) => {
                        setFilterAnchorEl(e.currentTarget);
                      }}
                    >
                      <FilterListIcon />
                    </Box>
                    <CheckboxPopover
                      anchorElement={filterAnchorEl}
                      options={statusOptions}
                      setOptions={setStatusOptions}
                      onClose={() => {
                        setFilterAnchorEl(null);
                      }}
                      selectAllCheckboxOption={false}
                      minWidth={220}
                      showCount
                      disableMaxHeight
                    />
                  </>
                )}
                <Box
                  sx={{
                    flex: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 0.5,
                    borderRadius: "4px",
                    background: "#0000000A",
                    ":hover": {
                      cursor: "pointer",
                      background: "#0000001F",
                    },
                  }}
                  onClick={(e) => {
                    setColumnAnchorEl(e.currentTarget);
                  }}
                >
                  <ViewColumnOutlinedIcon />
                </Box>
                <CheckboxPopover
                  anchorElement={columnAnchorEl}
                  options={columns}
                  setOptions={setColumns}
                  onClose={() => {
                    setColumnAnchorEl(null);
                  }}
                  selectAllCheckboxOption={false}
                  minWidth={220}
                  disableMaxHeight
                />
                <Box sx={{ flex: 1 }}>
                  <TextField
                    hiddenLabel
                    value={search}
                    onChange={(e) => {
                      try {
                        const text = e.target.value;
                        setSearch(text);
                      } catch (error) {
                        // Do nothing
                      }
                    }}
                    placeholder={"Search a Worker"}
                    variant="filled"
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <SearchIcon
                          style={{
                            height: "24px",
                            width: "24px",
                            color: "rgba(0,0,0,0.56)",
                          }}
                        />
                      ),
                      endAdornment:
                        search !== "" ? (
                          <ClearIcon
                            onClick={() => {
                              setSearch("");
                            }}
                            style={{
                              height: "24px",
                              width: "24px",
                              color: "rgba(0,0,0,0.56)",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <></>
                        ),
                    }}
                    sx={{
                      width: "100%",
                      border: "1px solid rgba(0,0,0,0.12)",
                      borderRadius: "4px",
                      input: {
                        paddingLeft: "8px",
                        color: "rgba(0,0,0,0.87)",
                        fontFamily: "Roboto",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        letterSpacing: "0.4px",
                      },
                    }}
                  />
                </Box>
              </Box>
            )}
            {activeStep === SET_PREFERRED_WORKERS && (
              <AssignShiftTable
                rows={availablePreferredWorkersForShiftRow ?? []}
                columns={columns}
                setWorkersSelected={setWorkersPreferredForAssignShift}
                searchValue={search}
                workersSelected={workersPreferredForAssignShift}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
              />
            )}
            {activeStep === ASSIGN_SHIFT && (
              <AssignShiftTable
                rows={availableWorkersForShiftRow ?? []}
                shiftMetadata={shiftMetadata}
                columns={columns}
                setWorkersSelected={setWorkersSelectedForAssignShift}
                readOnly={
                  Array.isArray(userRoles)
                    ? userRoles.includes("ReadOnly")
                    : false
                }
                searchValue={search}
                workersSelected={workersSelectedForAssignShift}
                totalWorkersRequired={
                  confirmedWorkersCount !== undefined
                    ? parseInt(shiftMetadata.numberOfWorkersNeeded) -
                      confirmedWorkersCount
                    : parseInt(shiftMetadata.numberOfWorkersNeeded) -
                      parseInt(shiftMetadata.slotsFilled)
                }
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
              />
            )}
            {activeStep === SHIFT_DETAILS && (
              <ShiftDetailsTable
                rows={rowsV2}
                columns={columns.filter((column: any) => column.checked)}
                setWorkersSelected={setWorkersSelected}
                isShiftPast={isPast(new Date(shiftMetadata?.endDateTime))}
                readOnly={
                  Array.isArray(userRoles)
                    ? userRoles.includes("ReadOnly")
                    : false
                }
                searchValue={search}
                totalWorkersRequired={
                  confirmedWorkersCount !== undefined
                    ? parseInt(shiftMetadata.numberOfWorkersNeeded) -
                      confirmedWorkersCount
                    : parseInt(shiftMetadata?.numberOfWorkersNeeded) -
                      parseInt(shiftMetadata?.slotsFilled)
                }
                statusOptions={statusOptions}
                loadingStatus={status}
              />
            )}
          </>
        </DialogContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pt={4}
          pb={4}
          pl={5.5}
          pr={5.5}
          sx={{
            borderTop: 1,
            borderColor: "rgba(0, 0, 0, 0.12)",
          }}
          maxWidth={"1200px"}
        >
          {activeStep !== SHIFT_DETAILS && <Box />}
          {activeStep === SHIFT_DETAILS && (
            status === "pending" ? (
              <Skeleton width={400} height={20} />) : (
            <Box style={{ marginRight: "24px" }}>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  color: "#00000099",
                }}
              >
                Published by {shiftMetadata?.publishedByName} on{" "}
                {shiftMetadata?.createdDate &&
                  `
											${formatDate({
                        dateStr: shiftMetadata?.publishedOn ?? "",
                        formatType: "CAPTION_DATE",
                      })} at ${formatDate({
                    dateStr: shiftMetadata?.publishedOn ?? "",
                    formatType: "TIME",
                  })}
										`}
              </Typography>
            </Box>)
          )}
          {activeStep === ASSIGN_SHIFT && (
            <ReviewWorkerAssignShiftCheckbox
              iAmSure={iAmSure}
              setIAmSure={setIAmSure}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {activeStep === SHIFT_DETAILS && (
              <>
                <Button variant="text" onClick={() => handleClose()}>
                  Cancel
                </Button>
                <Button
                  autoFocus
                  color="primary"
                  variant="contained"
                  sx={{
                    marginLeft: "8px",
                  }}
                  disabled={
                    (Array.isArray(userRoles)
                      ? userRoles.includes("ReadOnly")
                      : false) || isPast(new Date(shiftMetadata?.startDateTime))
                      ? true
                      : workersSelected.length === 0 ||
                        renotifyWorkersStatus === "pending" ||
                        renotifyWorkersStatus === "error"
                  }
                  onClick={() =>
                    dispatch(
                      renotifyWorkers({ workers: workersSelected, shiftId })
                    )
                  }
                >
                  {renotifyWorkersStatus === "pending" ? (
                    <Box position="relative">
                      <CircularProgress
                        size={25}
                        style={{ position: "absolute", left: "50%" }}
                      />
                      Renotifying
                    </Box>
                  ) : (
                    "Resend Notification"
                  )}
                </Button>
              </>
            )}
            {activeStep === SET_PREFERRED_WORKERS && (
              <>
                <Button
                  variant="text"
                  onClick={() => {
                    setWorkersPreferredForAssignShift(
                      originalWorkersPreferredForAssignShift
                    );
                    setActiveStep(SHIFT_DETAILS);
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  fullWidth
                  sx={{
                    marginLeft: "8px",
                  }}
                  disabled={
                    (Array.isArray(userRoles)
                      ? userRoles.includes("ReadOnly")
                      : false) || isPast(new Date(shiftMetadata?.startDateTime))
                      ? true
                      : setOTWorkersStatus === "pending" ||
                        JSON.stringify(
                          originalWorkersPreferredForAssignShift
                        ) === JSON.stringify(workersPreferredForAssignShift)
                  }
                  variant="contained"
                  loading={setOTWorkersStatus === "pending"}
                  onClick={() => {
                    const workers =
                      workersPreferredForAssignShift.map((worker) => {
                        return {
                          workerId: worker,
                        };
                      }) ?? [];
                    if (workers) {
                      dispatch(
                        setOTWorkers({
                          workers: workers,
                          shiftId: shiftId,
                          workersPreferredForAssignShift: workersPreferredForAssignShift,
                        })
                      );
                    }
                  }}
                  loadingPosition="start"
                  startIcon={<></>}
                  endIcon={<></>}
                  loadingIndicator={
                    <CircularProgress
                      size={16}
                      sx={{
                        color: "rgba(0, 0, 0, 0.38)",
                        marginLeft: "12px",
                      }}
                    />
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "24px",
                        letterSpacing: "0.46px",
                        textTransform: "uppercase",
                      }}
                    >
                      {setOTWorkersStatus === "pending" ? "SAVING" : "SAVE"}
                    </span>
                  </div>
                </LoadingButton>
              </>
            )}
            {activeStep === ASSIGN_SHIFT && (
              <>
                <Button
                  variant="text"
                  onClick={() => setActiveStep(SHIFT_DETAILS)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  fullWidth
                  sx={{
                    marginLeft: "8px",
                  }}
                  disabled={
                    (Array.isArray(userRoles)
                      ? userRoles.includes("ReadOnly")
                      : false) || isPast(new Date(shiftMetadata?.startDateTime))
                      ? true
                      : workersSelectedForAssignShift.length === 0 ||
                        assignWorkersStatus === "pending" ||
                        !iAmSure
                  }
                  variant="contained"
                  loading={assignWorkersStatus === "pending"}
                  onClick={() => {
                    dispatch(
                      assignWorkers({
                        workers: workersSelectedForAssignShift,
                        shiftId: shiftId,
                      })
                    );
                  }}
                  loadingPosition="start"
                  startIcon={<></>}
                  endIcon={<></>}
                  loadingIndicator={
                    <CircularProgress
                      size={16}
                      sx={{
                        color: "rgba(0, 0, 0, 0.38)",
                        marginLeft: "12px",
                      }}
                    />
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "24px",
                        letterSpacing: "0.46px",
                        textTransform: "uppercase",
                      }}
                    >
                      {assignWorkersStatus === "pending"
                        ? "ASSIGNING"
                        : "ASSIGN"}
                    </span>
                  </div>
                </LoadingButton>
              </>
            )}
          </Box>
        </Box>
        {setOTWorkersStatus === "fulfilled" &&
        getPreferredWorkerAssignedSuccessMessage() !== "" ? (
          <Snackbar
            open={setOTWorkersStatus === "fulfilled"}
            autoHideDuration={3000}
            onClose={() => {
              dispatch(resetSetOTWorkers());
              dispatch(resetSetOTWorkersResponse());
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            TransitionComponent={Transition}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              {getPreferredWorkerAssignedSuccessMessage()}
            </MuiAlert>
          </Snackbar>
        ) : null}
        {assignWorkersStatus === "fulfilled" &&
        getAssignedWorkerSuccessMessage() !== "" ? (
          <Snackbar
            open={assignWorkersStatus === "fulfilled"}
            autoHideDuration={3000}
            onClose={() => {
              dispatch(resetAssignWorkers());
              dispatch(resetAssignWorkersResponse());
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              {getAssignedWorkerSuccessMessage()}
            </MuiAlert>
          </Snackbar>
        ) : null}
        {status === "error" ? (
          <Snackbar
            open={status === "error"}
            autoHideDuration={5000}
            onClose={() => dispatch(resetShiftDetails())}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={handleClose}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box mb={1}>{errorMessage ?? "An error occurred"}</Box>
              </Box>
            </MuiAlert>
          </Snackbar>
        ) : null}
      </Dialog>
    </>
  );
};

export default ShiftDetailsDialog;
