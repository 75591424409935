import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";

interface JobRoleChipProps {
    name: string;
    color: string;
}

const JobRoleChip:React.FC<JobRoleChipProps> = ({ name, color }) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
			}}
		>
			<Tooltip title={name}>
				<Box
					sx={{
						borderRadius: "8px",
						paddingTop: "4px",
						paddingBottom: "4px",
						paddingLeft: "8px",
						paddingRight: "8px",
						backgroundColor: `#${color}`,
						border: `1px solid #${color}`,
						marginRight: "8px",
					}}
				>
					<Typography
						variant='body2'
						sx={{
							display: "box",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							boxOrient: "vertical",
							overflow: "hidden",
							width: "150px",
						}}
					>
						{name}
					</Typography>
				</Box>
			</Tooltip>
		</Box>
	);
}

export default JobRoleChip;
