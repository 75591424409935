import { Box } from "@mui/material";
import React from "react";

import { useMonthActivityController } from "../controllers/use-month-activity-controller";
import ActivityTable from "./ui/activity-table";
import KpiSummaries from "./ui/kpi-summaries";

const MonthlyActivityReport = () => {
	const [
		{ date, filteredData, exportData, search, columnSelector },
		{ setDate, setSearch, setColumnSelector },
	] = useMonthActivityController();

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<KpiSummaries />
			<ActivityTable
				date={date}
				setDate={setDate}
				data={filteredData}
				exportData={exportData}
				search={search}
				setSearch={setSearch}
				columnSelector={columnSelector}
				setColumnSelector={setColumnSelector}
			/>
		</Box>
	);
};

export default MonthlyActivityReport;
