import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Box, Typography } from "@mui/material";
import React from "react";

import SortedDataGrid7 from "../../../../../design/sorted-datagrid-7";
import useTableHeight from "../../../../../utils/use-table-height";
import useDayActivityColumn from "../../../controllers/use-day-activity-column";
import { useWorkerActivityData } from "../../../controllers/use-worker-activity-data";
import {
	ColumnSelector,
	WorkerActivityData,
} from "../../../store/worker-activity-slice-types";

const NoReportsAvailable = () => {
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box display='flex' flexDirection='column' alignItems='center'>
				<CalendarTodayIcon fontSize='large' />
				<Typography>No record found</Typography>
			</Box>
		</div>
	);
};
const DailyActivityTable = ({
	data,
	search,
	columnSelector,
}: {
	data?: WorkerActivityData[];
	search?: string;
	columnSelector?: ColumnSelector[];
}) => {
	const { boxRef, height } = useTableHeight();
	const { dailyActivityReportStatus } = useWorkerActivityData();
	const columns = useDayActivityColumn(search || "");

	const filteredColumns = columns.filter((column) =>
		columnSelector?.some((c) => column.field === c.field && c.checked)
	);

	return (
		<Box
			ref={boxRef}
			style={{
				overflow: "hidden",
				width: "100%",
				height: height,
			}}
		>
			<SortedDataGrid7
				rows={data ? data : []}
				columns={filteredColumns}
				pagination
				disableColumnSelector
				disableDensitySelector
				disableColumnMenu
				disableRowSelectionOnClick
				unstable_rowSpanning={true}
				defaultSortField='workerName'
				loading={dailyActivityReportStatus === "pending"}
				slots={{
					noRowsOverlay: NoReportsAvailable,
				}}
				initialState={{
					//   ...sortedWorkersRow.initialState,
					pagination: { paginationModel: { pageSize: 25 } },
				}}
				pageSizeOptions={[25, 50, 100]}
				sx={{
					overflow: "hidden",
					border: "none !important",
					"& .MuiDataGrid-columnHeader": {
						"&.hover-column-header": {
							"& .MuiDataGrid-columnSeparator": {
								display: "none",
							},
							"& .MuiDataGrid-sortIcon": {
								display: "none",
							},
						},
						"& .MuiDataGrid-columnSeparator": {
							display: "none",
						},
					},
					".MuiDataGrid-cell:focus": {
						outline: "none",
					},
					"& .MuiDataGrid-row": {
						cursor: "pointer",
					},
					"& .MuiTablePagination-root": {
						marginRight: "60px",
					},
					".MuiDataGrid-cell": {
						"&:focus": {
							outline: "none",
						},
						"&.hover-column-cell": {
							position: "sticky",
							right: 0,
							zIndex: 1,
						},
						"& .hover-content": {
							transition: "opacity 0.3s ease-in-out",
							opacity: 0,
						},
					},
					".MuiDataGrid-columnHeader:hover": {
						"& .MuiDataGrid-columnSeparator": {
							display: "flex",
						},
					},
					".MuiDataGrid-row:hover .hover-column-cell .hover-content": {
						opacity: 1,
						backgroundColor: "#f5f5f5",
					},
					"& .MuiDataGrid-row.Mui-selected .hover-column-cell .hover-content": {
						backgroundColor: "#e7e9f0",
					},
				}}
			/>
		</Box>
	);
};

export default DailyActivityTable;
