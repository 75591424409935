// MUI Imports
// Redux Imports
import { LinearProgress } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
// React Router Imports
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

import { light as theme } from "./theme";
import CrossSkillingDashboard from "./ts/components/cross-skilling-dashboard";
import MiniDrawer from "./ts/components/drawer";
import AdminConfiguration from "./ts/components/gat-admin";
import WorkerActivity from "./ts/components/worker-activity";
import DeepLink from "./ts/features/DeepLink/DeepLink";
import Login from "./ts/features/user/login/login";
import WorkersLogin from "./ts/features/web-worker-app/Login/WorkersLogin";
import AuthRoute from "./ts/utils/auth-route";
import DetectHome from "./ts/utils/detect-home";
import i18nFileGenerator from "./ts/utils/i18n-file-generator";
import PrivateRoute from "./ts/utils/private-route";
import withScrollToTop from "./ts/utils/scroll-to-top"; // Import HOC

const FactoryConfigCompaniesSettings = React.lazy(() =>
	import("./js/components/gat-admin/factory-config-companies-settings")
);
const WeeklySchedule = React.lazy(() =>
	import("./js/components/weekly-schedule")
);
const WorkcenterDashboard = React.lazy(() =>
	import("./js/components/workcenter-report")
);
const Dashboard = React.lazy(() =>
	import("./ts/components/attendance-reports")
);
const AbsenceReports = React.lazy(() =>
	import("./ts/components/absence-reports")
);
const AdminConfigSettings = React.lazy(() =>
	import(
		"./ts/components/gat-admin/admin-config-companies-settings/admin-config-settings"
	)
);
const InsightDashboard = React.lazy(() =>
	import("./ts/components/insight-dashboard")
);
const Jobs = React.lazy(() => import("./ts/components/jobs"));
const Skills = React.lazy(() => import("./ts/components/skills"));
const Workcenters = React.lazy(() => import("./ts/components/workcenters"));
const Workers = React.lazy(() => import("./ts/components/workers"));

const Certification = React.lazy(() => import("./ts/features/certifications"));
const SkillMatrix = React.lazy(() => import("./ts/features/skill-matrix"));
const ForgotPassword = React.lazy(() =>
	import("./ts/features/user/forgot-password")
);
const ResetPassword = React.lazy(() =>
	import("./ts/features/user/reset-password")
);
const Form = React.lazy(() =>
	import("./ts/features/web-worker-app/Components/Form")
);
const DownloadApp = React.lazy(() =>
	import("./ts/features/web-worker-app/DownloadApp")
);
const Settings = React.lazy(() =>
	import("./ts/features/web-worker-app/Settings")
);
const WorkerProfile = React.lazy(() =>
	import("./ts/features/web-worker-app/WorkerProfile")
);
const WorkersLanding = React.lazy(() =>
	import("./ts/features/web-worker-app/WorkersLanding")
);
const WorkersMain = React.lazy(() =>
	import("./ts/features/web-worker-app/WorkersMain")
);
const ShiftAcknowledgement = React.lazy(() =>
	import("./ts/features/web-worker-app/shift-acknowledgement")
);
const NotFound = React.lazy(() => import("./ts/components/NotFound"));
const ShiftSwaps = React.lazy(() => import("./ts/features/worker-requests"));

const DemoSetup = React.lazy(() => import("./ts/components/demo-setup"));

const themeConstructed = createTheme(theme);

function App() {
	const authenticated = useSelector((state) => state.user.authenticated);
	const workerAuthenticated = useSelector(
		(state) => state.worker.authenticated
	);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themeConstructed}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<Helmet>
						<script
							type='text/javascript'
							id='hs-script-loader'
							async
							defer
							src='//js.hs-scripts.com/21474340.js'
						/>
					</Helmet>
					<div className='App'>
						<Suspense fallback={<LinearProgress />}>
							<Router>
								<Switch>
									{/* Visible only without authentication */}
									<AuthRoute
										exact
										path='/login'
										component={withScrollToTop(Login)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<AuthRoute
										exact
										path='/forgot-password'
										component={withScrollToTop(ForgotPassword)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<AuthRoute
										exact
										path='/verifyEmail'
										component={withScrollToTop(ResetPassword)}
										authenticated={authenticated}
										workerApp={false}
									/>
									{/* Visible only with authentication */}
									<PrivateRoute
										exact
										path='/'
										component={withScrollToTop(DetectHome)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/flex-schedule'
										component={withScrollToTop(MiniDrawer)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/shift'
										component={withScrollToTop(MiniDrawer)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/manage-workers'
										component={withScrollToTop(Workers)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/manage-skills'
										component={withScrollToTop(Skills)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/manage-jobs'
										component={withScrollToTop(Jobs)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/workcenters'
										component={withScrollToTop(Workcenters)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/reports'
										component={withScrollToTop(Dashboard)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/absence-reports'
										component={withScrollToTop(AbsenceReports)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/worker-activity'
										component={withScrollToTop(WorkerActivity)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/admin-configuration'
										component={withScrollToTop(AdminConfiguration)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/insight'
										component={withScrollToTop(InsightDashboard)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/certifications'
										component={withScrollToTop(Certification)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/admin-settings'
										component={withScrollToTop(AdminConfigSettings)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/day-report'
										component={withScrollToTop(Dashboard)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/workcenter-report'
										component={withScrollToTop(WorkcenterDashboard)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/skills-matrix'
										component={withScrollToTop(SkillMatrix)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/cross-skilling'
										component={withScrollToTop(CrossSkillingDashboard)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/full-time-schedule'
										component={withScrollToTop(WeeklySchedule)}
										authenticated={authenticated}
										workerApp={false}
									/>
									<PrivateRoute
										exact
										path='/worker-requests'
										component={withScrollToTop(ShiftSwaps)}
										authenticated={authenticated}
										workerApp={false}
									/>
									{/* Only load route for demo reset for dev and demo branch */}
									{(process.env.REACT_APP_ENV === "demo" ||
										process.env.REACT_APP_ENV === "dev") && [
										<PrivateRoute
											key={"factory-settings"}
											exact
											path='/factory-settings'
											component={withScrollToTop(
												FactoryConfigCompaniesSettings
											)}
											authenticated={authenticated}
											workerApp={false}
										/>,
										<PrivateRoute
											key={"demo-setup"}
											path='/demo'
											component={withScrollToTop(DemoSetup)}
											authenticated={authenticated}
											workerApp={false}
										/>,
									]}
									{/* For dev use only */}
									{process.env.REACT_APP_ENV === "dev" && (
										<Route
											exact
											path='/i18n-file-generator'
											component={withScrollToTop(i18nFileGenerator)}
										/>
									)}
									<Route path='/wl'>
										<WorkersLogin />
									</Route>
									<PrivateRoute
										path='/w'
										authenticated={workerAuthenticated}
										component={withScrollToTop(WorkersLanding)}
										workerApp={true}
									/>
									<PrivateRoute
										path='/wd'
										authenticated={workerAuthenticated}
										component={withScrollToTop(WorkersMain)}
										workerApp={true}
									/>
									<PrivateRoute
										path='/ws'
										authenticated={workerAuthenticated}
										component={withScrollToTop(Settings)}
										workerApp={true}
									/>
									<PrivateRoute
										path='/wp'
										authenticated={workerAuthenticated}
										component={withScrollToTop(WorkerProfile)}
										workerApp={true}
									/>
									<PrivateRoute
										path='/wa'
										authenticated={workerAuthenticated}
										component={withScrollToTop(ShiftAcknowledgement)}
										workerApp={true}
									/>
									<Route path='/dl'>
										<DeepLink />
									</Route>
									<Route path={"/download-app"}>
										<DownloadApp />
									</Route>
									<Route path='/help-support'>
										<Form />
									</Route>
									<Route path='/' component={NotFound} />
								</Switch>
							</Router>
						</Suspense>
					</div>
				</LocalizationProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
