import { Box } from "@mui/material";
import format from "date-fns/format";
import React from "react";

import {
	DateRangePicker,
	ExportButton,
} from "../../../../../../export/attendance-reports";
import ColumnSelector from "../../../../../../ts/design/column-selector";
import Searchbar from "../../../../../design/search-bar";
import { ColumnSelector as ColumnSelectorComponent } from "../../../store/worker-activity-slice-types";
interface TableHeaderProps {
	date?: [string, string];
	setDate?: (date: [string, string]) => void;
	search?: string;
	setSearch?: (search: string) => void;
	columnSelector?: ColumnSelectorComponent[];
	setColumnSelector?: (columnSelector: ColumnSelectorComponent[]) => void;
	exportData?: () => void;
}

const TableHeader = ({
	date,
	setDate,
	search,
	setSearch,
	columnSelector,
	setColumnSelector,
	exportData,
}: TableHeaderProps) => {
	const handleSelectColumns = (checked: boolean, field: string) => {
		if (setColumnSelector && columnSelector) {
			setColumnSelector(
				columnSelector.map((column) =>
					column.field === field ? { ...column, checked } : column
				)
			);
		}
	};
	const exportedData = exportData ? exportData() : [];
	return (
		<Box display='flex' alignItems='center' justifyContent='space-between'>
			<Box display='flex' alignItems={"center"}>
				<ColumnSelector
					columns={columnSelector}
					handleSelectColumns={handleSelectColumns}
					disabledColumns={["workerName"]}
				/>
				<Searchbar
					placeholder={"Worker, Job Role/ Skill(s), Shift Location, Workcenter"}
					setSearch={setSearch}
					value={search}
					variant={"filled"}
					width={"60ch"}
				/>
				<DateRangePicker
					value={date || ["", ""]}
					setValue={
						setDate ||
						(() => {
							console.log("setDate");
						})
					}
					variant={"filled"}
					size={"small"}
				/>
			</Box>
			<Box mx={1} my={2}>
				<ExportButton data={exportedData} />
			</Box>
		</Box>
	);
};

export default TableHeader;
