import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { formatDate } from "../../../utils/helpers";
import {
	getMonthlyActivityReport,
	setSelectedKPI,
} from "../store/worker-activity-slice";
import {
	ColumnSelector,
	Shift,
	Skill,
	Worker,
} from "../store/worker-activity-slice-types";
import { useWorkerActivityData } from "./use-worker-activity-data";

export const useMonthActivityController = () => {
	const { start, end, monthlyActivityReportData } = useWorkerActivityData();

	const [columnSelector, setColumnSelector] =
		useState<ColumnSelector[]>(DEFAULT_COLUMNS);
	const [date, setDate] = useState<[string, string]>([start, end]);
	const [search, setSearch] = useState<string>("");
	const dispatch = useDispatch();

	const searchTerm = useMemo(() => search.toLowerCase(), [search]);

	const mapWorkerToShiftData = (item: Worker) => {
		if (item.shifts.length > 0) {
			return item.shifts.map((shift: Shift) => ({
				id: `${item.workerId}-${shift.shiftId}`,
				workerId: item.workerId,
				workerName: `${item.firstName} ${item.lastName}`,
				isShiftAvailable: true,
				...shift,
			}));
		}

		return [
			{
				id: `${item.workerId}`,
				workerId: item.workerId,
				workerName: `${item.firstName} ${item.lastName}`,
				isShiftAvailable: false,
				...DEFAULT_SHIFT,
			},
		];
	};

	const tableData = useMemo(
		() => monthlyActivityReportData.flatMap(mapWorkerToShiftData),
		[monthlyActivityReportData]
	);

	const getSearchableFields = (
		item: Shift & { workerName: string; isShiftAvailable: boolean }
	) => {
		const baseFields = [
			item.workerName,
			item.shiftLocationName,
			item.shiftWorkcenterName,
			item.publishedByName,
			item.jobName,
			item.skills
				? item.skills.map((skill: Skill) => skill.skillName).join(", ")
				: "",
			statusObj[item.attendanceStatus?.toLowerCase()]?.title || "",
			item.supervisorComments,
		];

		if (item.isShiftAvailable) {
			const shiftDate = formatDate({
				dateStr: item.shiftStartDateTime,
				formatType: "NUMERIC_DATE",
			});
			const shiftTime = formatDate({
				dateStr: item.shiftStartDateTime,
				formatType: "TIME_RANGE",
				endDateStr: item.shiftEndDateTime,
			});
			return [...baseFields, shiftTime, shiftDate];
		}

		return [...baseFields, "", ""];
	};

	const filteredData = useMemo(
		() =>
			tableData.filter((item) =>
				getSearchableFields(item)
					.map((field) => (field ?? "").toLowerCase())
					.some((field) => field.includes(searchTerm))
			),
		[tableData, searchTerm]
	);

	useEffect(() => {
		if (date[0] && date[1]) {
			dispatch(setSelectedKPI("ALL"));
			dispatch(
				getMonthlyActivityReport({ startDate: date[0], endDate: date[1] })
			);
		}
	}, [date, dispatch]);

	const exportData = () => {
		const data = filteredData.map((item) => {
			const baseExport = {
				"Worker Name": item.workerName,
				"Shift Date": "-",
				"Shift Time": "-",
				"Shift Location": "-",
				Workcenter: "-",
				"Published By": "-",
				"Job Role/Skill": "-",
				"Shift Attendance": "-",
				"Supervisor Comments": "-",
			};

			if (!item.isShiftAvailable) return baseExport;

			const shiftTime = formatDate({
				dateStr: item.shiftStartDateTime,
				formatType: "TIME_RANGE",
				endDateStr: item.shiftEndDateTime,
			});

			return {
				...baseExport,
				"Shift Date": formatDate({
					dateStr: item.shiftStartDateTime,
					formatType: "NUMERIC_DATE",
				}),
				"Shift Time": shiftTime,
				"Shift Location": item.shiftLocationName,
				Workcenter: item.shiftWorkcenterName,
				"Published By": item.publishedByName,
				"Job Role/Skill":
					item.jobName ||
					item.skills?.map((skill) => skill.skillName).join(", ") ||
					"-",
				"Shift Attendance":
					statusObj[item.attendanceStatus?.toLowerCase()]?.title || "-",
				"Supervisor Comments": item.supervisorComments || "-",
			};
		});
		// sort by worker name
		data.sort((a, b) => a["Worker Name"].localeCompare(b["Worker Name"]));
		return data;
	};

	return [
		{ date, exportData, filteredData, search, columnSelector },
		{ setDate, setSearch, setColumnSelector },
	] as const;
};

const DEFAULT_COLUMNS: ColumnSelector[] = [
	{ headerName: "Worker Name", field: "workerName", checked: true },
	{ headerName: "Shift Start Date", field: "shiftStartDate", checked: true },
	{ headerName: "Shift Hours", field: "shiftHours", checked: true },
	{ headerName: "Shift Location", field: "shiftLocation", checked: true },
	{ headerName: "Workcenter", field: "workcenter", checked: true },
	{ headerName: "Published By", field: "publishedByName", checked: true },
	{ headerName: "Job Role/Skill", field: "jobRoleSkills", checked: true },
	{ headerName: "Shift Attendance", field: "attendance", checked: true },
	{
		headerName: "Supervisor Comments",
		field: "supervisorComments",
		checked: true,
	},
];

const DEFAULT_SHIFT: Shift = {
	shiftId: "",
	shiftStartDateTime: "",
	shiftEndDateTime: "",
	shiftLocationName: "",
	shiftWorkcenterName: "",
	publishedByName: "",
	jobName: "",
	skills: [],
	attendanceStatus: "",
	supervisorComments: "",
};

interface IStatusObjType {
	[key: string]: {
		title: string;
	};
}

const statusObj: IStatusObjType = {
	p: {
		title: "Present",
	},
	a: {
		title: "No Show",
	},
	t: {
		title: "Tardy",
	},
	e: {
		title: "Excused Absence",
	},
	x: {
		title: "Excused Tardy",
	},
};
