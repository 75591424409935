// TODO - Type 'any' needs to be fixed.
import PageLayout from "@gigandtake/page-layout";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect } from "react";

import NavDrawer from "../drawer/ui";
import WorkerActivityDashboard from "./worker-activity-dashboard";

const WorkerActivity = () => {
	return (
		<Box sx={{ display: "flex", backgroundColor: "#F5F5F5" }}>
			<CssBaseline />
			<NavDrawer keepSettingOpen={false} keepReportOpen={true} />
			<Box sx={{ flexGrow: 1, overflow: "hidden" }}>
				<PageLayout>
					<WorkerActivityDashboard />
				</PageLayout>
			</Box>
		</Box>
	);
};

export default WorkerActivity;
