import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { updateCompanySettings } from "../../../../../../../export/gat-admin";
import useGatAdminConfigData from "../../../../../../components/gat-admin/controllers/use-gat-admin-config-data";
import { findDifferentKeys } from "../../../../utils";

const limitObj: any = {
	weekStartTime: "shift.weekStartTime",
	weekStartDay: "shift.weekStartDay",
	defaultDuration: "shift.defaultDuration",
	minDuration: "shift.minDuration",
	maxDuration: "shift.maxDuration",
	maxContinuousHours: "shift.maxContinuousHours",
	maxWorkHoursInADay: "workers.maxWorkHoursInADay",
	restrictFlexHours: "clientApp.restrictFlexHours",
	maxFlexHrsPerMonth: "job.maxFlexHrsPerMonth",
	flexMinWeeklyHours: "flexWorker.minWeeklyHours",
	flexMaxWeeklyHours: "flexWorker.maxWeeklyHours",
	fullMaxWeeklyHours: "fullTimeWorker.maxWeeklyHours",
	fullMinWeeklyHours: "fullTimeWorker.minWeeklyHours",
	partTimeMaxWeeklyHours: "partTimeWorker.maxWeeklyHours",
	partTimeMinWeeklyHours: "partTimeWorker.minWeeklyHours",
	schedulerOffset: "clientApp.schedulerOffset.enabled",
};

const initialLimitControl: any = {
	weekStartTime: "08:00:00",
	weekStartDay: "Monday",
	defaultDuration: 8,
	minDuration: 4,
	maxDuration: 10,
	maxContinuousHours: "12",
	maxWorkHoursInADay: "24",
	restrictFlexHours: false,
	maxFlexHrsPerMonth: 0,
	flexMaxWeeklyHours: 168,
	fullMaxWeeklyHours: 168,
	partTimeMaxWeeklyHours: 168,
	schedulerOffset: false,
};

const useLimitControlController = (entity: any) => {
	const dispatch = useDispatch();

	const {
		updateCompanySettingsStatus,
		getCompanySettingsStatus,
		companySettingsObj,
		locationSettingsObj,
		allSettings,
	} = useGatAdminConfigData();

	const [loading, setLoading] = useState(false);

	const [limitControl, setLimitControl] = useState(initialLimitControl);
	const [newLimitControl, setNewLimitControl] = useState(initialLimitControl);

	const settingsObj =
		entity === "location" ? locationSettingsObj : companySettingsObj;

	const handleSave = () => {
		setLoading(true);
		const result: any = findDifferentKeys(limitControl, newLimitControl);
		const changedValues = result.map((key: any) => {
			const obj = settingsObj[limitObj[key]]
				? settingsObj[limitObj[key]]
				: allSettings[limitObj[key]];
			return {
				...obj,
				value: newLimitControl[key],
			};
		});
		dispatch(updateCompanySettings(changedValues));
	};

	useEffect(() => {
		if (updateCompanySettingsStatus === "fulfilled") {
			setLimitControl(newLimitControl);
			setLoading(false);
		}
	}, [updateCompanySettingsStatus, newLimitControl]);

	useEffect(() => {
		if (Object.keys(settingsObj).length > 0) {
			const obj = {
				weekStartTime: settingsObj["shift.weekStartTime"].value,
				weekStartDay: settingsObj["shift.weekStartDay"].value,
				defaultDuration: settingsObj["shift.defaultDuration"].value,
				minDuration: settingsObj["shift.minDuration"].value,
				maxDuration: settingsObj["shift.maxDuration"].value,
				maxContinuousHours: settingsObj["shift.maxContinuousHours"].value,
				maxWorkHoursInADay: settingsObj["workers.maxWorkHoursInADay"].value,
				restrictFlexHours: settingsObj["clientApp.restrictFlexHours"].value,
				maxFlexHrsPerMonth: settingsObj["job.maxFlexHrsPerMonth"]?.value ?? 0,
				flexMaxWeeklyHours:
					settingsObj["flexWorker.maxWeeklyHours"]?.value ?? 168,
				fullMaxWeeklyHours:
					settingsObj["fullTimeWorker.maxWeeklyHours"]?.value ?? 168,
				partTimeMaxWeeklyHours:
					settingsObj["partTimeWorker.maxWeeklyHours"]?.value ?? 168,
				schedulerOffset:
					settingsObj["clientApp.schedulerOffset.enabled"]?.value ?? false,
			};
			setLimitControl(obj);
			setNewLimitControl(obj);
		}
	}, [settingsObj, entity]);

	return [
		{
			limitControl,
			newLimitControl,
			updateCompanySettingsStatus,
			getCompanySettingsStatus,
			loading,
		},
		{
			setNewLimitControl,
			handleSave,
		},
	];
};

export default useLimitControlController;
