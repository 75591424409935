import { Box, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
	setCurrentLocationData,
	updateCreateNewLocation,
} from "../../../../../../export/gat-admin";
import SortedDataGridPro6 from "../../../../../design/sorted-datagrid-pro-6";
import useTableHeight from "../../../../../utils/use-table-height";
import LocationPlaceholder from "../assets/location_placeholder.png";
import LocationPlaceholderBackground from "../assets/location_placeholder_background.png";
import useLocationColumns from "./controllers/use-location-columns";


interface LocationTableProps {
	locations: any;
	getLocationsStatus: any;
	getLocationsErrorMessage: any;
	companyId: any;
	openLocationForm: any;
	searchText: string;
}

const LocationTable: React.FC<LocationTableProps> = ({
	locations,
	getLocationsStatus,
	getLocationsErrorMessage,
	companyId,
	openLocationForm,
	searchText,
}) => {
	const dispatch = useDispatch();
	const columns:any = useLocationColumns({ searchText });
	const { height, boxRef } = useTableHeight();

	const [locationsArray, setLocationsArray] = useState([]);

	const handleClick = useCallback(
		(params) => {
			const loc = locations.find((location: any) => location.id === params.id);
			dispatch(updateCreateNewLocation(false));
			dispatch(setCurrentLocationData({ ...loc, companyId }));
			openLocationForm();
		},

		[locations, companyId, openLocationForm, dispatch]
	);

	useEffect(() => {
		try {
			// Injecting setLocationData into data
			const newLocations:any = [];
			if (locations.length > 0) {
				locations.forEach((location:any) => {
					newLocations.push({
						...location,
						handleClick: (value:any) => handleClick(value),
					});
				});
				newLocations.sort((a:any, b:any) => {
					return -(a.id - b.id); // Latest id first
				});
				setLocationsArray(newLocations);
			} else {
				setLocationsArray([]);
			}
		} catch (e) {
			// Do nothing
		}
	}, [locations, handleClick]);

	return (
		<Box
			ref={boxRef}
			style={{
				overflow: "hidden",
				height: "calc(100vh - 335px)",
				width: "100%",
				minHeight: "200px",
			}}>
			<SortedDataGridPro6
				columns={columns}
				rows={locationsArray}
				getRowHeight={() => "auto"}
				loading={getLocationsStatus === "pending"}
				pagination
				disableRowSelectionOnClick
				disableColumnSelector
				disableDensitySelector
				disableColumnReorder
				disableColumnMenu
				initialState={{
					// ...locationsArray.initialState,
					pagination: { paginationModel: { pageSize: 10 } },
				}}
				pageSizeOptions={[10, 25, 50, 100]}
				sx={{
					overflow: "hidden",
					border: "none !important",
					"& .MuiDataGrid-selectedRowCount": {
						display: "none !important",
					},
					"& .MuiDataGrid-toolbar": {
						justifyContent: "flex-end",
						"& .MuiTablePagination-root": {
							marginLeft: "auto",
						},
					},
					"& .MuiDataGrid-columnHeader": {
						"&.hover-column-header": {
							"& .MuiDataGrid-columnSeparator": {
								display: "none",
							},
							"& .MuiDataGrid-sortIcon": {
								display: "none",
							},
						},
						"& .MuiDataGrid-columnSeparator": {
							display: "none",
						},
					},
					".MuiDataGrid-cell:focus": {
						outline: "none",
					},
					"& .MuiDataGrid-row": {
						cursor: "pointer",
					},
					"& .MuiTablePagination-root": {
						marginRight: "60px",
					},
					".MuiDataGrid-cell": {
						"&:focus": {
							outline: "none",
						},
						"&.hover-column-cell": {
							position: "sticky",
							right: 0,
							zIndex: 1,
						},
						"& .hover-content": {
							transition: "opacity 0.3s ease-in-out",
							opacity: 0,
						},
					},
					".MuiDataGrid-columnHeader:hover": {
						"& .MuiDataGrid-columnSeparator": {
							display: "flex",
						},
					},
					".MuiDataGrid-row:hover .hover-column-cell .hover-content": {
						opacity: 1,
						backgroundColor: "#f5f5f5"
					},
					"& .MuiDataGrid-row.Mui-selected .hover-column-cell .hover-content": {
						backgroundColor: "#e7e9f0",
					},
				}}
				slots={{
					noRowsOverlay: () => (
						<Stack height='100%' alignItems='center' justifyContent='center'>
							<Box
								sx={{
									position: "relative",
									height: "100px",
									width: "100px",
								}}
							>
								<img
									src={LocationPlaceholderBackground}
									alt=''
									height={100}
									width={100}
									style={{
										position: "absolute",
										opacity: 0.25,
									}}
								/>
								<img
									src={LocationPlaceholder}
									alt=''
									height={70}
									width={70}
									style={{
										position: "absolute",
										top: 15,
										left: 15,
									}}
								/>
							</Box>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontSize: "14px",
									fontWeight: 400,
									color: "rgba(0, 0, 0, 0.38)",
									marginTop: "4px",
									textAlign: "center",
								}}
							>
								{getLocationsErrorMessage !== ""
									? "Error: " + getLocationsErrorMessage
									: "No Location added"}
							</Typography>
						</Stack>
					),
				}}
			/>
		</Box>
	);
};

export default LocationTable;
