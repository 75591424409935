import { Box } from "@mui/material";
import React from "react";

import SortedDataGridPro6 from "../../../../../../../design/sorted-datagrid-pro-6";
import useShiftScheduleColumns from "../../controllers/use-shift-schedule-columns";

interface ScheduleTableProps {
	data: Array<any>;
	getDuration: (
		startTime: string,
		endTime: string,
		breakDuration: number
	) => string | 0;
}

const ScheduleTable: React.FC<ScheduleTableProps> = ({ data, getDuration }) => {
	const columns = useShiftScheduleColumns(getDuration);

	return (
		<Box mt={3} style={{ height: data.length > 0 ? "auto" : 300 }}>
			<SortedDataGridPro6
				columns={columns}
				rows={data}
				disableRowSelectionOnClick
				disableColumnSelector
				disableDensitySelector
				disableColumnPinning
				disableColumnReorder
				disableColumnMenu
				pagination
				initialState={{
					...data,
					pagination: { paginationModel: { pageSize: 10 } },
					filter: {},
				}}
				pageSizeOptions={[10, 25, 50]}
				getRowHeight={() => 40}
				sx={{
					position: "sticky",
					border: "none !important",
					"&>.MuiDataGrid-main": {
						"& div div div div >.MuiDataGrid-cell": {
							borderBottom: "none",
						},
					},

					"& .MuiDataGrid-row": {
						border: "none !important",
					},
					".MuiDataGrid-cell": {
						"&:focus": {
							outline: "none",
						},
						"&.hover-column-cell": {
							position: "sticky",
							right: 24,
							zIndex: 1,
						},
						"& .hover-content": {
							transition: "opacity 0.3s ease-in-out",
							opacity: 0,
						},
					},
					".MuiDataGrid-cell:focus-within": {
						outline: "none",
					},
					"& .MuiDataGrid-columnHeader:focus-within": {
						outline: "none",
					},
					".MuiDataGrid-row:hover .hover-column-cell .hover-content": {
						opacity: 1,
						backgroundColor: "#f5f5f5",
					},
				}}
			/>
		</Box>
	);
};

export default ScheduleTable;
