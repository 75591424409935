/* eslint-disable no-mixed-spaces-and-tabs */
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import { Box, Divider, Stack } from "@mui/material";
import { differenceInMinutes } from "date-fns";
import React from "react";
import { useDispatch } from "react-redux";

import { useWorkerActivityData } from "../../../controllers/use-worker-activity-data";
import { setSelectedDayKPI } from "../../../store/worker-activity-slice";
import { Shift, Worker } from "../../../store/worker-activity-slice-types";
import ActivityKpiCard from "../activity-kpi-card";

const KpiSummaries = () => {
	const {
		dayKPISummaries,
		dailyActivityReportStatus,
		selectedDayKPI,
		allDailyActivityReportData,
	} = useWorkerActivityData();
	const dispatch = useDispatch();

	const handleClick = React.useCallback(
		(kpi: string) => {
			dispatch(setSelectedDayKPI(kpi === selectedDayKPI ? "ALL" : kpi));
		},
		[selectedDayKPI, dispatch]
	);

	const { totalHours, totalShifts, workerPercentages } = React.useMemo(() => {
		const total_hours = allDailyActivityReportData.reduce(
			(acc: number, item: Worker) =>
				acc +
				item.shifts.reduce(
					(acc: number, shift: Shift) =>
						acc +
						Math.round(
							Math.abs(
								differenceInMinutes(
									new Date(shift.shiftEndDateTime),
									new Date(shift.shiftStartDateTime)
								)
							) / 60
						),
					0
				),
			0
		);

		const total_shifts = allDailyActivityReportData.reduce(
			(acc: number, item: Worker) => acc + item.shifts.length,
			0
		);

		const totalWorkers = allDailyActivityReportData.length;

		return {
			totalHours: total_hours,
			totalShifts: total_shifts,
			workerPercentages: {
				withShifts:
					totalWorkers === 0
						? 0
						: Math.round(
								(dayKPISummaries["WORKERS WITH AT LEAST 1 SHIFT"] /
									totalWorkers) *
									100
						  ),
				withoutShifts:
					totalWorkers === 0
						? 0
						: Math.round(
								(dayKPISummaries["WORKERS WITH NO SHIFTS"] / totalWorkers) * 100
						  ),
			},
		};
	}, [allDailyActivityReportData, dayKPISummaries]);

	const commonProps = {
		loading: dailyActivityReportStatus,
		handleClick,
	};
	return (
		<Box>
			<Stack direction={{ xs: "column", sm: "row" }}>
				<ActivityKpiCard
					{...commonProps}
					icon={<GroupsIcon />}
					iconBackgroundColor='#E8F5E9'
					iconColor='#26A69A'
					kpiDataCount={dayKPISummaries["WORKERS WITH AT LEAST 1 SHIFT"]}
					title='Workers with at least 1 Shift'
					subTitle={`${workerPercentages.withShifts}% of your Workers`}
					isToggled={selectedDayKPI === "WORKERS WITH AT LEAST 1 SHIFT"}
					kpiData='WORKERS WITH AT LEAST 1 SHIFT'
				/>
				<ActivityKpiCard
					icon={<GroupsIcon />}
					iconBackgroundColor={"#F9DEDC"}
					iconColor={"#E44B48"}
					kpiDataCount={dayKPISummaries["WORKERS WITH NO SHIFTS"]}
					title={"Workers with no Shifts"}
					subTitle={`${workerPercentages.withoutShifts}% of your Workers`}
					isToggled={selectedDayKPI === "WORKERS WITH NO SHIFTS"}
					kpiData={"WORKERS WITH NO SHIFTS"}
					{...commonProps}
				/>
				<ActivityKpiCard
					icon={<CheckCircleOutlinedIcon />}
					iconBackgroundColor={"#CEE1FF"}
					iconColor={"#448AF7"}
					kpiDataCount={dayKPISummaries["HOURS COMPLETED"]}
					title={"Hours Completed"}
					subTitle={`${
						totalHours === 0
							? 0
							: Math.round(
									(dayKPISummaries["HOURS COMPLETED"] / totalHours) * 100
							  )
					}% of Shifts`}
					isToggled={selectedDayKPI === "HOURS COMPLETED"}
					kpiData={"HOURS COMPLETED"}
					{...commonProps}
				/>
				<ActivityKpiCard
					icon={<DoDisturbOutlinedIcon />}
					iconBackgroundColor={"#F9DEDC"}
					iconColor={"#E44B48"}
					kpiDataCount={dayKPISummaries["NO CALL NO SHOWS"]}
					title={"No Call No Shows"}
					subTitle={`${
						totalShifts === 0
							? 0
							: Math.round(
									(dayKPISummaries["NO CALL NO SHOWS"] / totalShifts) * 100
							  )
					}% of Shifts`}
					isToggled={selectedDayKPI === "NO CALL NO SHOWS"}
					kpiData={"NO CALL NO SHOWS"}
					{...commonProps}
				/>
			</Stack>
			<Divider sx={{ mt: 2 }} />
		</Box>
	);
};

export default React.memo(KpiSummaries);
