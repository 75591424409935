import {
	combineReducers,
	configureStore,
	getDefaultMiddleware,
} from "@reduxjs/toolkit";

// import ReportSlice from  "../features/Reports/ReportSlice";
import AbsenceReportsSlice from "../../ts/components/absence-reports/store/absence-reports-slice";
import ReportSlice from "../../ts/components/attendance-reports/store/attendance-reports-slice";
import GATAdminConfigurationSlice from "../../ts/components/gat-admin/store/gat-admin-slice";
import SkillInsightReportSlice from "../../ts/components/insight-dashboard/slices/skill-insight-report-slice";
import WorkerInsightReportSlice from "../../ts/components/insight-dashboard/slices/worker-insight-report-slice";
import ManageJobsSlice from "../../ts/components/jobs/store/jobs-slice";
import ShiftSchedulerSlice from "../../ts/components/scheduler/store/scheduler-slice";
import SkillsSlice from "../../ts/components/skills/store/skills-slice";
import WorkerActivitySlice from "../../ts/components/worker-activity/store/worker-activity-slice";
import ManageWorkersSlice from "../../ts/components/workers/store/manage-workers-slice";
import CertificationsSlice from "../../ts/features/certifications/store/certifications-slice";
import NavigationDrawerSlice from "../../ts/features/navigation-drawer/navigation-drawer-slice";
import SkillsMatrixSlice from "../../ts/features/skill-matrix/store/skills-matrix-slice";
import userReducer from "../../ts/features/user/user-slice";
import WebWorkerSlice from "../../ts/features/web-worker-app/store/web-worker-slice";
import WorkerRequestsSlice from "../../ts/features/worker-requests/store/worker-requests-slice";
import WeeklyScheduleSlice from "../components/weekly-schedule/store/weekly-schedule-slice";

const combinedReducer = combineReducers({
	user: userReducer,
	supervisor: ShiftSchedulerSlice,
	worker: WebWorkerSlice,
	report: ReportSlice,
	absenceReports: AbsenceReportsSlice,
	navigationDrawer: NavigationDrawerSlice,
	manageWorkers: ManageWorkersSlice,
	gatAdminConfiguration: GATAdminConfigurationSlice,
	manageJobs: ManageJobsSlice,
	weeklySchedule: WeeklyScheduleSlice,
	workerRequests: WorkerRequestsSlice,
	workerInsightReportData: WorkerInsightReportSlice,
	skillInsightReportData: SkillInsightReportSlice,
	certifications: CertificationsSlice,
	skills: SkillsSlice,
	skillsMatrix: SkillsMatrixSlice,
	workerActivity: WorkerActivitySlice,
});

const rootReducer = (state, action) => {
	if (action.type === "user/logoutUser") {
		return combinedReducer(undefined, action);
	}
	return combinedReducer(state, action);
};

export default configureStore({
	reducer: rootReducer,
	middleware: [...getDefaultMiddleware()],
});
