/* eslint-disable no-prototype-builtins */
import { Tab } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Tabs from "@mui/material/Tabs";
import React, { useEffect } from "react";

import Button from "../../../../../../../design/button";
import DialogWrapper from "../../../../../../../design/dialog-wrapper";
import ForcedOvertime from "./forced-overtime";
// import ConfigurationForm from "./configuration-form";
import VoluntarySignup from "./voluntary-signup";

type ConfigurationDialogProps = {
	open: boolean;
	handleClose: () => void;
	shiftAllocationRules: any;
	setShiftAllocationRules: any;
	// id?: number;
	locationData: any;
	setLocationData: any;
	// title: string;
	allocationTypeDetails: any;
	initialRules: any;
};

const ConfigurationDialog = ({
	open,
	handleClose,
	shiftAllocationRules,
	setShiftAllocationRules,
	locationData,
	setLocationData,
	allocationTypeDetails,
	initialRules,
}: ConfigurationDialogProps) => {
	const config = shiftAllocationRules.find(
		(rule: { shiftAllocationTypeId: number }) =>
			rule.shiftAllocationTypeId === allocationTypeDetails?.id
	);
	const [value, setValue] = React.useState(0);
	const initialConfig = initialRules.find(
		(rule: { shiftAllocationTypeId: number }) =>
			rule.shiftAllocationTypeId === allocationTypeDetails?.id
	);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	function TabPanel(props: any) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role='tabpanel'
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && <Box sx={{ p: 3, pb: 1 }}>{children}</Box>}
			</div>
		);
	}

	const onDialogClose = () => {
		handleClose();
		setShiftAllocationRules(() => {
			return shiftAllocationRules.map((rule: any) => {
				if (rule.shiftAllocationTypeId === allocationTypeDetails?.id) {
					return initialRules.find(
						(initialRule: any) =>
							initialRule.shiftAllocationTypeId === allocationTypeDetails?.id
					);
				}
				return rule;
			});
		});
		setValue(0);
	};

	return (
		<Dialog
			open={open}
			onClose={onDialogClose}
			aria-describedby='alert-dialog-slide-description'
			maxWidth={false}
		>
			<DialogWrapper
				// dialogTitle='Worker Type and Location based allocation - configuration'
				dialogTitle={allocationTypeDetails?.dialogTitle}
				dialogSubTitle={
					"Configure the rules that you want to apply for the shift allocation"
				}
				dialogContent={
					<Box
						sx={{
							height: "350px",
							width: "965px",
						}}
					>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label='simple tabs example'
						>
							<Tab label='Voluntary Signup' />
							<Tab label='Forced Overtime' />
						</Tabs>
						<TabPanel value={value} index={0}>
							<VoluntarySignup
								shiftAllocationRules={shiftAllocationRules}
								setShiftAllocationRules={setShiftAllocationRules}
								id={allocationTypeDetails?.id}
								config={config}
								voluntaryRules={allocationTypeDetails?.voluntaryRules}
								allocationType={allocationTypeDetails?.type}
							/>
						</TabPanel>
						<TabPanel value={value} index={1}>
							<ForcedOvertime
								config={config}
								shiftAllocationRules={shiftAllocationRules}
								setShiftAllocationRules={setShiftAllocationRules}
								id={allocationTypeDetails?.id}
								forcedOTRules={allocationTypeDetails?.forcedOTRules}
								allocationType={allocationTypeDetails?.type}
							/>
						</TabPanel>
					</Box>
				}
				actionButtons={
					<Box
						display='flex'
						justifyContent='space-between'
						width='160px'
						mt={-5}
					>
						<Button color='primary' variant='text' onClick={onDialogClose}>
							Cancel
						</Button>
						<Button
							variant='contained'
							color='primary'
							type='submit'
							onClick={() => {
								setLocationData({
									...locationData,
									allocationSettings: shiftAllocationRules,
								});
								onDialogClose();
							}}
							disabled={
								JSON.stringify(initialConfig) === JSON.stringify(config)
							}
						>
							Done
						</Button>
					</Box>
				}
			/>
		</Dialog>
	);
};

export default ConfigurationDialog;
