import {
	Avatar,
	Box,
	Chip,
	Stack,
	Switch,
	Typography,
} from "@mui/material";
import React from "react";

interface ForcedOvertimeProps {
	config: any;
	setShiftAllocationRules: any;
	shiftAllocationRules: any;
	id: any;
	forcedOTRules: string[];
	allocationType: string;
}

const ForcedOvertime: React.FC<ForcedOvertimeProps> = ({
	config,
	setShiftAllocationRules,
	shiftAllocationRules,
	id,
	forcedOTRules,
	allocationType,
}) => {
	const Title: React.FC<{ children: React.ReactNode }> = ({ children }) => (
		<Typography
			variant='body1'
			sx={{
				fontFamily: "Roboto",
				fontSize: "16px",
				fontWeight: 400,
				lineHeight: "24px",
				letterSpacing: "0.15px",
				textAlign: "left",
			}}
		>
			{children}
		</Typography>
	);

	return (
		<Box>
			<Box display='flex' flexDirection={"column"}>
				{/* <Title>Force Assign Workers</Title> */}
				<Typography
					variant='body1'
					sx={{
						fontSize: "16px",
						fontWeight: 700,
						lineHeight: "24px",
						letterSpacing: "0.15px",
					}}
				>
					Settings for Forced Overtime Allocation
				</Typography>
				<Box display={"flex"} alignItems={"center"}>
					<Switch
						checked={!!config?.forceAssignSettings?.isEnabled}
						disabled={allocationType === "SENIORITY"}
						onChange={(e) => {
							setShiftAllocationRules(
								shiftAllocationRules.map((rule: any) => {
									if (rule.shiftAllocationTypeId === id) {
										return {
											...rule,
											forceAssignSettings: {
												...rule.forceAssignSettings,
												isEnabled: e.target.checked,
												applicableBeforeDays: e.target.checked ? 2 : 0,
											},
										};
									}
									return rule;
								})
							);
						}}
					/>
					<Typography>
						Force assign Workers selected by the supervisor on any open spots
						remaining from voluntary signups
					</Typography>
				</Box>
			</Box>
			{allocationType !== "SENIORITY" && (
				<Box mt={3}>
					<Title>Force Shift Allocation Order (among remaining Workers)</Title>
					<Box>
						<Stack direction='column' alignItems='start'>
							{forcedOTRules.map((rule, index) => (
								<Chip
									avatar={<Avatar>{index + 1}</Avatar>}
									label={rule}
									key={index}
									sx={{
										// height: "auto",
										"& .MuiChip-label": {
											display: "block",
											whiteSpace: "normal",
										},
										marginTop: "8px",
									}}
								/>
							))}
						</Stack>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default ForcedOvertime;
