import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";

export default function ActivityKpiCard({
	icon,
	iconBackgroundColor,
	iconColor,
	kpiDataCount,
	title,
	subTitle,
	loading,
	isToggled,
	handleClick,
	onClickActionType,
	kpiData,
}: any) {
	return (
		<Box
			sx={{
				width: "302px",
				height: "55px",
				boxShadow: isToggled
					? null
					: "0px 3px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
				borderRadius: "10px",
				padding: "8px",
				cursor: "pointer",
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				backgroundColor: isToggled ? "rgba(0, 0, 0, 0.04)" : null,
				marginRight: 2,
				marginBottom: 1,
				outline: isToggled ? "1px solid #8A99B8" : null,
			}}
			onClick={() => loading !== "pending" && handleClick(kpiData)}
		>
			{loading === "pending" ? (
				<Box
					mt={1}
					mb={2}
					sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
				>
					<Skeleton
						variant='rounded'
						width={32}
						height={32}
						sx={{
							borderRadius: "15px",
							marginRight: 2,
						}}
					/>
					<Skeleton animation='wave' width={80} height={40} />
				</Box>
			) : (
				<>
					<Box
						style={{
							backgroundColor: iconBackgroundColor,
							display: "inline-flex",
							alignItems: "center",
							justifyContent: "center",
							width: 40,
							height: 40,
							borderRadius: "50%",
							color: iconColor,
						}}
					>
						{icon}
					</Box>

					<Typography variant='h5' pl={0.2} p={0.5} fontWeight={"bold"}>
						{kpiDataCount}
					</Typography>
					<Box pl={0.5}>
						<Typography style={{ fontWeight: "450", fontSize: "0.875rem" }}>
							{title}
						</Typography>
						<Typography
							sx={{
								fontSize: "14px",
								fontWeight: "400",
								fontStyle: "italic",
								color: "gray",
								textWrap: "nowrap",
							}}
						>
							{subTitle}
						</Typography>
					</Box>
				</>
			)}
		</Box>
	);
}
