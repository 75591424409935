import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
	Avatar,
	Box,
	Chip,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import React from "react";

import { AllocationSettings } from "../../../../../store/gat-admin-slice-types";
import InputButtonGroup from "./input-button-group";

interface VoluntarySignupProps {
	config: any;
	setShiftAllocationRules: any;
	shiftAllocationRules: any;
	id: any;
	voluntaryRules: string[];
	allocationType: string;
}

const VoluntarySignup: React.FC<VoluntarySignupProps> = ({
	config,
	setShiftAllocationRules,
	shiftAllocationRules,
	id,
	voluntaryRules,
	allocationType,
}) => {
	const Title: React.FC<{ children: React.ReactNode }> = ({ children }) => (
		<Typography
			variant='body1'
			sx={{
				fontFamily: "Roboto",
				fontSize: "16px",
				fontWeight: 400,
				lineHeight: "24px",
				letterSpacing: "0.15px",
				textAlign: "left",
			}}
		>
			{children}
		</Typography>
	);

	const defaultSignupTitle =
		"Time frame for workers to mark interest in a published shift. After this window, shift will be assigned based on allocation rule.";
	const urgentShiftTitle =
		"An urgent shift is when the default application window is more than the time between shift publish and shift start time. The window for an urgent shift has to be shorter than the default application window. Recommended - 15minutes.";
	return (
		<Box>
			<Typography
				variant='body1'
				sx={{
					fontSize: "16px",
					fontWeight: 700,
					lineHeight: "24px",
					letterSpacing: "0.15px",
				}}
			>
				Settings for Voluntary Signup Allocation
			</Typography>
			<Box display='flex' justifyContent='space-between'>
				<Box my={2}>
					<Box display='flex' alignItems='center'>
						<Title>Default Signup Window</Title>
						<Tooltip title={defaultSignupTitle}>
							<InfoOutlinedIcon sx={{ marginLeft: "8px", fontSize: "18px" }} />
						</Tooltip>
					</Box>

					<Box my={2} display='flex'>
						<Box display='flex' alignItems='center' mr={2}>
							<InputButtonGroup
								handleIncrement={() =>
									setShiftAllocationRules(
										shiftAllocationRules.map((rule: AllocationSettings) => {
											if (rule.shiftAllocationTypeId === id) {
												return {
													...rule,
													defaultWindowSizeMins: rule.defaultWindowSizeMins
														? Math.floor(rule.defaultWindowSizeMins / 60) * 60 + 60
														: 60,
												};
											}
											return rule;
										})
									)
								}
								handleDecrement={() =>
									setShiftAllocationRules(
										shiftAllocationRules.map((rule: AllocationSettings) => {
											if (rule.shiftAllocationTypeId === id) {
												return {
													...rule,
													defaultWindowSizeMins:
														Math.floor(rule.defaultWindowSizeMins / 60) * 60 -
														60,
												};
											}
											return rule;
										})
									)
								}
								value={
									config?.defaultWindowSizeMins
										? Math.floor(config?.defaultWindowSizeMins / 60)
										: 0
								}
							/>
							<Typography ml={1}>Hours (from Shift Publish Time)</Typography>
						</Box>
					</Box>
				</Box>
				<Box my={2}>
					<Box display='flex' alignItems='center'>
						<Title>Signup Window for Short Notice Shifts</Title>
						<Tooltip title={urgentShiftTitle}>
							<InfoOutlinedIcon sx={{ marginLeft: "8px", fontSize: "18px" }} />
						</Tooltip>
					</Box>

					<Box my={2} display='flex'>
						<Box display='flex' alignItems='center'>
							<InputButtonGroup
								handleIncrement={() =>
									setShiftAllocationRules(
										shiftAllocationRules.map((rule: AllocationSettings) => {
											if (rule.shiftAllocationTypeId === id) {
												return {
													...rule,
													urgentWindowSizeMins: rule.urgentWindowSizeMins
														? rule.urgentWindowSizeMins + 1
														: 1,
												};
											}
											return rule;
										})
									)
								}
								handleDecrement={() =>
									setShiftAllocationRules(
										shiftAllocationRules.map((rule: AllocationSettings) => {
											if (rule.shiftAllocationTypeId === id) {
												return {
													...rule,
													urgentWindowSizeMins: rule.urgentWindowSizeMins - 1,
												};
											}
											return rule;
										})
									)
								}
								value={config?.urgentWindowSizeMins ?? 0}
							/>
							<Typography ml={1}>Minutes (from Shift Publish Time)</Typography>
						</Box>
					</Box>
				</Box>
				<Box my={2} />
			</Box>
			{allocationType !== "SENIORITY" && (
				<Box>
					<Box display='flex' alignItems='center' marginBottom={"12px"}>
						<Title>
							Voluntary Shift Allocation Order (among Interested Workers)
						</Title>
					</Box>

					<Stack direction='column' alignItems='start' gap={1}>
						{voluntaryRules.map((rule, index) => (
							<Chip
								avatar={<Avatar>{index + 1}</Avatar>}
								label={rule}
								key={index}
								sx={{
									// height: "auto",
									"& .MuiChip-label": {
										display: "block",
										whiteSpace: "normal",
									},
								}}
							/>
						))}
					</Stack>
				</Box>
			)}
			{allocationType === "SENIORITY" && (
				<Box>
					<Title>TIE BREAKER RULES</Title>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "12px",
							fontWeight: 400,
							lineHeight: "20px",
							letterSpacing: "1px",
							textAlign: "left",
						}}
					>
						Seniority is calculated in number of days since worker joining date.
						Select the rule to allocate shift when 2 or more workers have the
						same seniority.
					</Typography>
					<Box my={2}>
						<FormControl>
							<RadioGroup
								aria-labelledby='demo-radio-buttons-group-label'
								defaultValue={config?.tieBreakerProp ?? "rscore"}
								name='radio-buttons-group'
							>
								<FormControlLabel
									value='rscore'
									control={<Radio />}
									label='Karma Points'
									onChange={() => {
										setShiftAllocationRules(
											shiftAllocationRules.map((rule: AllocationSettings) => {
												if (rule.shiftAllocationTypeId === id) {
													return {
														...rule,
														tieBreakerProp: "rscore",
													};
												}
												return rule;
											})
										);
									}}
								/>
								<FormControlLabel
									value='totalCompletedHrs'
									control={<Radio />}
									label='Total no. of shifts hours completed'
									onChange={() => {
										setShiftAllocationRules(
											shiftAllocationRules.map((rule: AllocationSettings) => {
												if (rule.shiftAllocationTypeId === id) {
													return {
														...rule,
														tieBreakerProp: "totalCompletedHrs",
													};
												}
												return rule;
											})
										);
									}}
								/>
							</RadioGroup>
						</FormControl>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default VoluntarySignup;
