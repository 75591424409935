import { SxProps, Theme } from "@mui/material/styles";
import {
	DataGridPro,
	DataGridProProps,
	GridColDef,
	GridSortModel,
} from "@mui/x-data-grid-pro-7";
import * as React from "react";

interface SortedDataGrid7Props
	extends Omit<DataGridProProps, "columns" | "sortModel"> {
	columns: any[];
	rows: any[];
	defaultSortField?: string;
	sx: SxProps<Theme>;
}

const SortedDataGrid7: React.FC<SortedDataGrid7Props> = ({
	columns,
	rows,
	defaultSortField = "name",
	sx,
	...otherProps
}) => {
	// Define default ascending sort model
	const [sortModel, setSortModel] = React.useState<GridSortModel>([
		{
			field: defaultSortField,
			sort: "asc",
		},
	]);

	const defaultSx: SxProps<Theme> = {
		"& .MuiDataGrid-iconButtonContainer .MuiBadge-root": {
			display: "none", // Hide the 99+ badge
		},
		"& .MuiDataGrid-columnHeader:focus": {
			outline: "none",
		},
		"& .MuiDataGrid-scrollbar--vertical": {
			width: "6px",
		},
		"& .MuiDataGrid-scrollbar--horizontal": {
			height: "6px",
		},
	};

	const mergedSx: SxProps<Theme> = {
		...defaultSx,
		...sx, // User's sx will override defaultSx if provided
	};

	return (
		<DataGridPro
			rows={rows}
			sx={mergedSx}
			columns={columns}
			sortingOrder={["desc", "asc"]}
			sortModel={sortModel}
			onSortModelChange={(model) => setSortModel(model)}
			{...otherProps}
		/>
	);
};

export default SortedDataGrid7;
